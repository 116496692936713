import React, { useState } from "react";
import PropTypes from "prop-types";
import "../css/ea.css"; // Certifique-se de importar corretamente o seu CSS
import axios from "axios";
import noprofile from "../assets/img/blankprofile.webp";

const PerfilCompletoColab = ({ colaborador, onClose, onExcluirColaborador }) => {
  

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleExcluir = () => {
    setShowConfirmation(true);
  };

  const confirmarExclusao = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/funcionarios/${colaborador.IdFuncionario}`);
      setShowConfirmation(false);
      onExcluirColaborador(colaborador.IdFuncionario); // Chamada da função para atualizar a lista
      onClose();
    } catch (error) {
      console.error('Erro ao excluir colaborador:', error);
    }
  };

  return (
    <div className="flexrow head-profile">
      <div className="flexrow w30">
        <img
          className="border100 profilepic w90"
          src={colaborador.fotoPerfilColab ? `${process.env.REACT_APP_API_URL}/${colaborador.fotoPerfilColab}` : noprofile}
          alt="Imagem de Perfil"
        />
      </div>
      <div className="gap5 flexcolumn w70 ">
      
        <h3 className="bold" >{colaborador.nome}</h3>
        <span className="ups" >UPS {colaborador.ups}</span>
        <span>{colaborador.email}</span>
       
  
        <div className="flexrow">
          <button className="btn-black" onClick={onClose}>
            Fechar
          </button>
          <div className="btn-black" onClick={handleExcluir}>
            <span>Excluir</span>
          </div>
           </div>
      </div>
  
      {showConfirmation && (
        <div className="modal-background">
          <div className="modal">
            <h3>Confirmar Exclusão</h3>
            <p>Deseja realmente excluir o colaborador {colaborador.nome}?</p>
            <div className="modal-buttons">
              <button onClick={confirmarExclusao}>Sim</button>
              <button onClick={() => setShowConfirmation(false)}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PerfilCompletoColab.propTypes = {
  colaborador: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onExcluirColaborador: PropTypes.func.isRequired, // Adicionando a propriedade onExcluirColaborador
};

export default PerfilCompletoColab;
