import React, { useState, useEffect } from 'react';
import noimage from '../assets/img/noimage.svg';

const ConteudoCompletoPostado = ({ post, onVoltar }) => {
  const [imagemAberta, setImagemAberta] = useState(null);
  const [urlsImagens, setUrlsImagens] = useState([]);

  useEffect(() => {
    if (post.midias) {
      const midiasLimpa = post.midias.replace(/[\[\]"\s]/g, '');
      const midiasArray = midiasLimpa.split(',')
        .map(url => `https://painel.rise.app.br/${url.replace(/\\/g, '')}`)
        .filter(url => url !== 'https://painel.rise.app.br/' && url.trim() !== '');

      setUrlsImagens(midiasArray);
    }
  }, [post.midias]);

  // Função auxiliar para verificar se a URL é de um vídeo
  const isVideo = (url) => {
    return url.match(/\.(mp4|webm)$/i);
  };

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    return data.toLocaleDateString('pt-BR');
  };

  const handlePrev = () => {
    setImagemAberta((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : urlsImagens.length - 1));
  };

  const handleNext = () => {
    setImagemAberta((prevIndex) => (prevIndex < urlsImagens.length - 1 ? prevIndex + 1 : 0));
  };

  const handleClose = () => {
    setImagemAberta(null);
  };

  const hasMultipleImages = urlsImagens.length > 1;

  return (
    <div className="flexcolumn" >
      <div className="tandline">
        <h2>Conteúdo Completo</h2>
        <div className="line300"></div>
      </div>

      {post.legendaAlterada ? (
        <>
          <p className="textop inative">Original</p>
          <p className='legenda inative'>{post.legenda}</p>
          <p className="textop">Sua Versão</p>
          <p className="legenda alterada">{post.legendaAlterada}</p>
        </>
      ) : (
        <p className='legenda'>{post.legenda}</p>
      )}


      {/* Renderização condicional da div */}
      {urlsImagens.length > 0 && (
        <div className='flexrow imagepostfull'>
          {urlsImagens.map((url, index) => (
            isVideo(url) ? (
              <video key={index} controls style={{ maxWidth: '250px', width: '100%', height: 'auto', margin: '10px 0' }}>
                <source src={url} type="video/mp4" />
                Seu navegador não suporta vídeos.
              </video>
            ) : (
              <img
                key={index}
                src={url}
                alt={`Conteúdo ${index + 1}`}
                style={{ maxWidth: '250px', width: '100%', height: 'auto', margin: '10px 0', cursor: 'pointer' }}
                onClick={() => setImagemAberta(index)}
              />
            )
          ))}
        </div>
      )}

      {imagemAberta !== null && (
        <div className='popup_midias'>
          <img className='imagefull'
            src={urlsImagens[imagemAberta].replace(/\\/g, '')}
            alt={`Conteúdo ${imagemAberta + 1}`}
            style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
          />

          <div className='flexrowcenter'>
            {hasMultipleImages && (
              <>
                <button className='btn' onClick={handlePrev} style={{ marginRight: '10px' }}>Anterior</button>
                <button className='btn' onClick={handleNext} style={{ marginRight: '10px' }}>Próximo</button>
              </>
            )}
            <button className='btn' onClick={handleClose} style={{ float: 'right' }}>Fechar</button>
          </div>
        </div>
      )}

      <p>{formatarData(post.createdAt)}</p>
      <div className='flexrow'>
        <button className='btn' onClick={onVoltar}>Voltar</button>
        <a className='btn' href={post.linkedinPostURL} target="_blank" rel="noopener noreferrer">Ver no LinkedIn</a>
      </div>
    </div>
  );
};

export default ConteudoCompletoPostado;
