import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Home from './Home';
import MainDashboard from './MainDashboard';
import DashboardEmpresa from './DashboardEmpresa';
import DashboardColab from './DashboardColab';
import RecuperacaoSenha from './RecuperacaoSenha';
import EmailConfirmado from './EmailConfirmado';
import CadastrarColab from './components/CadastrarColab';
import CadastrarEmpresa from './CadastrarEmpresa';
import EscolherPlano from './EscolherPlano';
import PaymentPage from './PaymentPage';
import CadastrarColabComCodigo from './CadastrarColabComCodigo';
import DefinirSenha from './DefinirSenha';
import ConfirmationPage from './ConfirmationPage';
import ConteudoPorLink from './components/ConteudoPorLink'; // Importe o novo componente


import TestePage from './TestePage';
import { UserProvider } from './UserContext';

// Carregar a chave pública do Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <UserProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <Routes>

           
         
            <Route path="/" element={<Home />} />
            <Route path="/DashboardEmpresa" element={<DashboardEmpresa />} />
            <Route path="/PaymentPage" element={<PaymentPage />} />
            <Route path="/conteudo/:postId" element={<ConteudoPorLink />} />

            <Route path="/confirmation" element={<ConfirmationPage />} />
            <Route path="/TestePage" element={<TestePage />} />
            <Route path="/MainDashboard" element={<MainDashboard />} />
            <Route path="/email-confirmado" element={<EmailConfirmado />} />
            <Route path="/definir-senha" element={<DefinirSenha />} />
            <Route path="/RecuperacaoSenha" element={<RecuperacaoSenha />} />
            <Route path="/DashboardColab" element={<DashboardColab />} />
            <Route path="/CadastrarColab" element={<CadastrarColab />} />
            <Route path="/CadastrarEmpresa" element={<CadastrarEmpresa />} />
            <Route path="/EscolherPlano" element={<EscolherPlano />} />
            <Route path="/CadastrarColabComCodigo" element={<CadastrarColabComCodigo />} />
          </Routes>
        </Router>
      </Elements>
    </UserProvider>
  );
}

export default App;
