import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import Cookies from 'js-cookie';
import noprofile from "../assets/img/blankprofile.webp";
import more from "../assets/img/more.svg";

const AjustesC = () => {
  const [colaboradorData, setColaboradorData] = useState({
    nome: Cookies.get('funcionarioNome') || "",
    telefone: Cookies.get('funcionarioTelefone') || "",
    email: Cookies.get('funcionarioEmail') || "",
   
  });
  const [fotoPerfil, setFotoPerfil] = useState(Cookies.get('funcionarioFotoPerfilColab') || null);
  const [novaFoto, setNovaFoto] = useState(null);
  const [zoom, setZoom] = useState(1);
  const editorRef = useRef(null);
  const [erroMsg, setErroMsg] = useState("");
  const [atualizacaoSucesso, setAtualizacaoSucesso] = useState(false);
  const [imageKey, setImageKey] = useState(Date.now());

  const funcionarioId = Cookies.get("funcionarioId");
  console.log('id do funcio:', funcionarioId);


  const onDrop = (acceptedFiles) => {
    setNovaFoto(acceptedFiles[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setColaboradorData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleZoomChange = (e) => {
    setZoom(parseFloat(e.target.value));
  };

  const handleSubmit = async (e) => {
   
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(colaboradorData).forEach(key => {
      formDataToSend.append(key, colaboradorData[key]);
    });

    if (novaFoto) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob((blob) => {
        formDataToSend.append("fotoPerfilColab", blob, "colaborador-foto.webp");
        sendUpdate(formDataToSend);
      }, 'image/webp');
    } else {
      sendUpdate(formDataToSend);
    }
  };

  const sendUpdate = async (formData) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/colaborador/${funcionarioId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("Dados do colaborador atualizados com sucesso:", response.data);
      Cookies.set('funcionarioNome', colaboradorData.nome);
      Cookies.set('funcionarioTelefone', colaboradorData.telefone);
      Cookies.set('funcionarioEmail', colaboradorData.email);
      Cookies.set('funcionarioLinkedin', colaboradorData.linkedin);
      Cookies.set('funcionarioCargo', colaboradorData.cargo);
      Cookies.set('funcionarioDataAdmissao', colaboradorData.dataAdmissao);
      Cookies.set('funcionarioIdade', colaboradorData.idade);
      Cookies.set('funcionarioGenero', colaboradorData.genero);
      if (response.data.fotoPerfilColab) {
        Cookies.set('funcionarioFotoPerfilColab', response.data.fotoPerfilColab);
        setFotoPerfil(response.data.fotoPerfilColab);
        setImageKey(Date.now());
      }

      setAtualizacaoSucesso(true);
      setErroMsg('');

    } catch (error) {
      console.error("Erro ao atualizar dados do colaborador:", error);
      setErroMsg("Erro ao atualizar dados. Por favor, tente novamente mais tarde.");
    }
  };

  if (atualizacaoSucesso) {
    return (
      <div>
        <h2>Atualização feita com sucesso!</h2>
        <button className="btn" onClick={() => window.location.reload()}>Recarregar</button>
      </div>
    );
  }

  return (
    <div>
      <div className="flexcolumn">
        <div className="tandline">
          <h2>Ajustes do Colaborador</h2>
          <div className="line300"></div>
        </div>

        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="flexrow mb15">
            {novaFoto && (
              <AvatarEditor
                ref={editorRef}
                image={typeof novaFoto === "string" ? novaFoto : URL.createObjectURL(novaFoto)}
                width={250}
                height={250}
                border={25}
                borderRadius={125}
                color={[255, 255, 255, 255]} // fundo todo branco proposital para sumir com o que foi cortado
                scale={zoom}
                rotate={0}
                backgroundColor={"transparent"}
              />
            )}

            {fotoPerfil && !novaFoto && (
              <div className="foto-preview">
                <img className="border100" src={`${process.env.REACT_APP_API_URL}/${fotoPerfil}?v=${imageKey}`} alt="Foto de Perfil Atual" />
              </div>
            )}

            {!fotoPerfil && !novaFoto && (
              <div className="foto-preview">
                <img className="border100" src={noprofile} alt="Foto de Perfil Padrão" />
              </div>
            )}

            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <img src={more} alt="Adicionar Foto" className="custom-file-icon" />
            </div>
          </div>

          <div className="form-fields-container">
            {Object.entries(colaboradorData).map(([key, value]) => (
              <div className="form-field" key={key}>
                <label>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                <input type="text" name={key} value={value} onChange={handleChange} />
              </div>
            ))}
          </div>
          {erroMsg && <div className="error-msg">{erroMsg}</div>}

          <button type="submit" className="btn">Atualizar</button>
        </form>
      </div>
    </div>
  );
};

export default AjustesC;
