import React, { useState, useEffect, useRef } from "react";
import "../css/ea.css";
import axios from "axios";
import Cookies from "js-cookie";
import MediaManager from "./MediaManager";
import imageCompression from 'browser-image-compression';

import GerenciarTags from "./GerenciarTags"; // Substituído por GerenciarTags
import UPSSelector from "./UPSSelector";
import ValiditySetter from "./ValiditySetter";
import Atualizar from '../Atualizar'; // Certifique-se de usar o caminho correto para o arquivo Atualizar.js

const NovoPost = () => {
  const [legenda, setLegenda] = useState("");
  const [alteravel, setAlteravel] = useState(false);
  const [tagsSelecionadas, setTagsSelecionadas] = useState([]);
  const [midias, setMidias] = useState([]);
  const [empresaId, setEmpresaId] = useState("");
  const [ups, setUps] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [erro, setErro] = useState("");
  const [validadeAtiva, setValidadeAtiva] = useState(false);
  const [dataValidade, setDataValidade] = useState(null);
  const planoNome = Cookies.get('plano_nome');
  const f_Enterprise = ['enterprise_anual', 'enterprise_semestral'].includes(planoNome);

  useEffect(() => {
    const empresaIdFromCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("empresaId"))
      ?.split("=")[1];
    setEmpresaId(empresaIdFromCookie);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
  
    if (ups === null) {
      alert("Por favor, selecione uma pontuação (UPS).");
      setLoading(false);
      return;
    }
  
    const formData = new FormData();
    formData.append("legenda", legenda);
    formData.append("empresaId", empresaId);
    formData.append("ups", ups);
    formData.append("alteravel", alteravel);
  
    // Adiciona as tags ao FormData
    tagsSelecionadas.forEach((tag) => {
      formData.append("tags[]", tag.id);
    });
  
    // Se validade está ativa, adiciona ao formData
    if (validadeAtiva && dataValidade) {
      formData.append("validade", dataValidade);
    }
  
    // Configurações para compressão e conversão para .webp
    const options = {
      useWebWorker: true,
      fileType: 'image/webp', // Converter para .webp
    };
    
  
    try {
      // Converte cada imagem para .webp e adiciona ao FormData
      for (const file of midias) {
        const compressedFile = await imageCompression(file, options);
        formData.append("midias", compressedFile);
      }
  
      // Faz a requisição para o backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/createPost`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
  
      // Resetar o estado após o sucesso
      setLegenda("");
      setMidias([]);
      setUps(null);
      setValidadeAtiva(false);
      setDataValidade(null);
      setMessage("Post publicado com sucesso!");
      Atualizar().atualizarContagemPosts(empresaId);
  
    } catch (error) {
      console.error("Erro ao enviar o novo post:", error);
      setMessage("Erro ao publicar o post.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="flex-space w100">
      <div className="flexcolumn">
        <div className="tandline">
          <h2>Novo Post</h2>
          <div className="line300"></div>
          {['expert_anual', 'expert_semestral', 'experience'].includes(planoNome) && (
            <h5>Limite Mensal {Cookies.get('totalPostsNoMes') || 0}/{Cookies.get('limitePosts') || 0}</h5>
          )}
        </div>

        {message && <div className="verde message">{message}</div>}

        {loading ? (
          <div className="loading-container2">
            <div className="loading-spinner2"></div>
          </div>
        ) : (
          <>
           
            <form className="flexcolumn gap20" onSubmit={handleSubmit}  >
              <GerenciarTags
                f_Enterprise={f_Enterprise}
                tagsSelecionadas={tagsSelecionadas}
                setTagsSelecionadas={setTagsSelecionadas}
              />
              <div className="flexcolumn">
                <label htmlFor="legenda">Legenda:</label>
                <textarea
                  className="legendapost"
                  id="legenda"
                  name="legenda"
                  value={legenda}
                  onChange={(e) => setLegenda(e.target.value)}
                  rows="4"
                  required
                ></textarea>

                <div className="flexrow">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      className="custom-switch"
                      id="alteravel"
                      checked={alteravel}
                      onChange={(e) => setAlteravel(e.target.checked)}
                      disabled={!f_Enterprise}
                    />
                    <label className="label1" htmlFor="alteravel">
                      Permitir alterações na legenda
                    </label>
                  </div>

                  {!f_Enterprise && (
                    <p className="alert-text">
                      Faça upgrade do plano para permitir alterações na legenda.
                    </p>
                  )}
                </div>
              </div>
              <UPSSelector
                f_Enterprise={f_Enterprise}
                ups={ups}
                handleUpsChange={setUps}
              />
              <ValiditySetter
                f_Enterprise={f_Enterprise}
                validadeAtiva={validadeAtiva}
                setValidadeAtiva={setValidadeAtiva}
                dataValidade={dataValidade}
                setDataValidade={setDataValidade}
              />
              <MediaManager
                midias={midias}
                setMidias={setMidias}
                erro={erro}
                setErro={setErro}
              />
               {erro && <div className="error-message">{erro}</div>}
              <div className="flexrow">
                <button className="btn" type="submit">
                  Publicar
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default NovoPost;
