import React from 'react';

const UPSSelector = ({ f_Enterprise, ups, handleUpsChange }) => {
  return (
    <div className="flexcolumn">
      <label htmlFor="ups">UPS (Pontuação)</label>
      <div className="flexrow">
        <div id="ups" className="ups-options">
          {[5, 10, 15, 20, 25].map((value) => (
            <button className="btn"
              type="button"
              key={value}
              onClick={() => handleUpsChange(value)}
              style={{
                backgroundColor: ups === value ? '#343434' : 'initial',
                color: ups === value ? '#fff' : 'initial',
                border: '1px solid #000',
                cursor: f_Enterprise || value <= 10 ? 'pointer' : 'not-allowed',
                opacity: f_Enterprise || value <= 10 ? 1 : 0.5
              }}
              disabled={!(f_Enterprise || value <= 10)}
            >
              {value}
            </button>
          ))}
        </div>
        {!f_Enterprise && (
          <p className="alert-text">
            Faça upgrade para usar UPS acima de 10.
          </p>
        )}
      </div>
    </div>
  );
};

export default UPSSelector;
