import React, { useState, useEffect } from "react";
import "./css/ea.css";
import PerfilRankingMin from "./components/PerfilRankingMin";
import Colaboradores from "./components/Colaboradores";
import Empresas from "./components/Empresas";
import ListaPostsFeitos from "./components/ListaPostsFeitos";
import ListaPosts from "./components/ListaPosts";
import Ajustes from "./components/AjustesE";
import { ReactComponent as ConteudoIcon } from './assets/img/conteudo.svg';
import noprofile from "./assets/img/blankprofile.webp";
import axios from "axios";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import sair from "./assets/img/sair.svg";
import useLogout from './useLogout';
import ConfirmEmail from "./CofirmEmail";
import Atualizar from './Atualizar';




import { ReactComponent as NewIcon } from './assets/img/mais.svg';
import { ReactComponent as SettingsIcon } from './assets/img/settings.svg';
import { ReactComponent as ColabIcon } from './assets/img/colaboradores.svg';
import { ReactComponent as InicioIcon } from './assets/img/inicio.svg';



const DashboardEmpresa = () => {
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [colaboradores, setColaboradores] = useState([]); // Estado para armazenar os colaboradores
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [empresaData, setEmpresaData] = useState(null); // Estado para armazenar os dados da empresa
  const [loading, setLoading] = useState(true);
  const handleLogout = useLogout();


  const navigate = useNavigate(); // Mova isso para fora da função handleLogout

  useEffect(() => {
    const verificarCookies = async () => {
      // Verificar se o empresaId está presente no cookie
      const empresaId = Cookies.get('empresaId');
      
      if (!empresaId) {
        // Se não houver empresaId no cookie, desloga o usuário
        handleLogout();
      } else {
        // Se o empresaId estiver presente, continua com as verificações
        Atualizar().fetchAndStoreEmpresaData(empresaId);
        
      }
    };
  
    verificarCookies(); // Chame a função assíncrona dentro do useEffect
  }, [navigate]);



  useEffect(() => {
    const emailConfirmed = Cookies.get('empresaemailConfirmed');
    console.log('Valor do cookie empresaemailConfirmed:', emailConfirmed);
    setShowConfirmEmail(emailConfirmed === 'false');



    setSelectedComponent("ListaPosts");
    setSelectedButton("ListaPosts");

    // Função para buscar os dados do perfil da empresa após o login
    const fetchEmpresaData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/empresa?empresaId=${Cookies.get('empresaId')}`
        );
        setEmpresaData(response.data);
      } catch (error) {
        console.error("Erro ao obter dados do perfil da empresa:", error);
      }
    };

    // Função para buscar os colaboradores ordenados por ranking da empresa
    const fetchRanking = async () => {
      try {
        const token = Cookies.get("token"); // Verifica o token de autenticação
        const empresaId = Cookies.get("empresaId"); // Obtenha o ID da empresa dos cookies
    
        if (!token) {
          throw new Error("Usuário não autenticado");
        }
    
        if (!empresaId) {
          throw new Error("ID da empresa não especificado");
        }
    
        console.log("Token obtido dos cookies:", token);
        console.log("ID da empresa obtido dos cookies:", empresaId);
    
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/rankingColaboradores`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            empresaId: empresaId
          }
        });
        setColaboradores(response.data);
      } catch (error) {
        console.error("Erro ao buscar ranking de colaboradores:", error);
      }
    };

    // Chame as funções para buscar os dados do perfil da empresa e o ranking dos colaboradores
    Promise.all([fetchEmpresaData(), fetchRanking()])
      .then(() => setLoading(false)) // Defina o estado de carregamento para false quando os dados forem carregados
      .catch(error => console.error("Erro ao buscar dados:", error));
  }, []); // Executa apenas na montagem (equivalente a componentDidMount)



  const handleButtonClick = (componentName) => {
    setSelectedComponent(componentName);
    setSelectedButton(componentName);
  };

  return (
    <div className="mainfc">
      {showConfirmEmail && <ConfirmEmail />}
      {loading && (
        // Renderiza a animação de carregamento ocupando toda a tela
        <div className="loading-container2">
          <div className="loading-spinner"></div>
        </div>
      )}

      {!loading && (

        <div className="esquerda">
          <div className="prevprofile">
            <div className="flexcolumn">
              <div className="flexrow fixed_top">
                <div className="fotoperfil">
                  {/* Verifique se os dados da empresa estão disponíveis e se a URL do logo não está vazia */}
                  {empresaData ? (
                    <img
                      className="w100p border100"
                      src={empresaData.logo ? `https://painel.rise.app.br/${empresaData.logo}` : noprofile}
                      alt="Imagem de Perfil"
                    />
                  ) : (
                    <img
                      className="w100p border100"
                      src={noprofile}
                      alt="Sem imagem de perfil"
                    />
                  )}
                </div>


                <div className="dadosperfil">
                  {/* Exibe os dados da empresa se estiverem disponíveis */}
                  {empresaData && (
                    <>
                      <span className="fontnome">{empresaData.nome}</span>
                     
                      {/* Adicione mais campos de dados da empresa conforme necessário */}
                    </>
                  )}
                </div>
              </div>

              <div className="btns-perfil">


                <div
                  className={`btn2  ${selectedButton === "ListaPosts" ? "selected" : ""}`}
                  onClick={() => handleButtonClick("ListaPosts")}
                >
                  <InicioIcon className="icon" />
                  <span className="icon_text">Inicio</span>
                </div>
                <div
                className={`btn2  ${selectedButton === "Publicados" ? "selected" : ""}`}
                onClick={() => handleButtonClick("Publicados")}
              >
                <ConteudoIcon className="icon" />
                <span>Publicados</span>
              </div>

                <div className="flexrow">



                  <div
                    className={`btn2 w70 w_m100 ${selectedButton === "Ajustes" ? "selected" : ""}`}
                    onClick={() => handleButtonClick("Ajustes")}
                  >
                    <SettingsIcon className="icon" />
                    <span className="icon_text" >Ajustes</span>
                  </div>

                  <div className="btn2 w30 mnone" onClick={handleLogout}>
                    <img src={sair} className="icon" />
                    <span className="icon_text" >Sair</span>
                  </div>

                </div>

                <div
                  className={`btn2  ${selectedButton === "Colaboradores" ? "selected" : ""}`}
                  onClick={() => handleButtonClick("Colaboradores")}
                >
                  <ColabIcon className="icon" />
                  <span className="icon_text" >Colaboradores</span>
                </div>

                <div
                  className={`btn2  ${selectedButton === "Empresas" ? "selected" : ""}`}
                  onClick={() => handleButtonClick("Empresas")}
                >
                  <NewIcon className="icon" />
                  <span className="icon_text" >Empresas</span>
                </div>

              </div>

            </div>
          </div>

          <div className="recentes">
            <div className="tandline">
              <h2>UPS</h2>
              <div className="line"></div>
            </div>
            <div className="recentes">
              {colaboradores.slice(0, 6).map((colaborador, index) => (
                <PerfilRankingMin
                  key={colaborador.IdFuncionario}
                  ranking={index + 1}
                  nome={colaborador.nome}
                  ups={colaborador.ups}
                  fotoPerfilColab={colaborador.fotoPerfilColab}
                />
              ))}
            </div>
          </div>

        </div>
      )}
      <div className="linhadiv mnone"></div>

      <div className="direita">
        {selectedComponent === "Colaboradores" && <Colaboradores />}
        {selectedComponent === "Empresas" && <Empresas/>}
        {selectedComponent === "Publicados" && <ListaPostsFeitos />}
        
        
        {selectedComponent === "ListaPosts" && <ListaPosts />}
        {selectedComponent === "Ajustes" && <Ajustes />}
      </div>
    </div>
  );
};
 
export default DashboardEmpresa;
