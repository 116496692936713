import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp";

const ListaEmpresas = ({ handleVerMais }) => {
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    async function fetchEmpresas() {
      try {
        const token = Cookies.get("token"); // Verificação do token de autenticação
        if (!token) {
          throw new Error("Usuário não autenticado");
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/empresas`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log("Empresas recebidas:", response.data); // Log dos dados recebidos
        setEmpresas(response.data);
      } catch (error) {
        console.error("Erro ao buscar empresas:", error);
      }
    }

    fetchEmpresas();
  }, []);

  return (
    <div>
      <div className="tandline">
        <h2>Empresas</h2>
        <div className="line300"></div>
      </div>
      <div className="flexcolumn listacolab">
        {empresas.map((empresa, index) => (
          <div key={index} className="flexrow">
            <div className="imagepr">
              <div className="profile-pink">
                <div className="profile-pink-detalhe">
                  <span className="ranking-nv">{index + 1}</span>
                </div>
                <img
                  className="wid60 border100"
                  src={empresa.logo ? `${process.env.REACT_APP_API_URL}/${empresa.logo}` : noprofile}
                  alt="Logo da Empresa"
                />
              </div>
            </div>
            <div className="flexrow">
              <div className="flexcolumn">
                <span className="titpostr">{empresa.nome}</span>
              </div>
              <div className="btn-black" onClick={() => {
                console.log("Empresa selecionada para ver mais:", empresa); // Log da empresa selecionada
                handleVerMais(empresa);
              }}>
                <span>Ver Mais</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListaEmpresas;
