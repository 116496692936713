

import React, { useState, useEffect, useRef } from "react";
import imageCompression from 'browser-image-compression';
import moreIcon from "../assets/img/more.svg";
import trashIcon from "../assets/img/trash.svg";
import openIcon from "../assets/img/open.svg";

const MediaManager = ({ midias, setMidias, erro, setErro }) => {
  const fileInputRef = useRef(null);
  const [midiaAberta, setMidiaAberta] = useState(null);

  const handleMidiasChange = async (e) => {
    setErro("");
    const files = Array.from(e.target.files);
    
    // Contagem de vídeos e outras mídias já selecionadas
    const videoCount = midias.filter((file) => file.type.startsWith('video')).length;
    const otherMediaCount = midias.filter((file) => !file.type.startsWith('video')).length;
  
    // Contagem de novos vídeos e outras mídias que estão sendo selecionadas
    const newVideoCount = files.filter((file) => file.type.startsWith('video')).length;
    const newOtherMediaCount = files.filter((file) => !file.type.startsWith('video')).length;
  
    // Se já existe um vídeo ou o novo arquivo é vídeo e já tem outras mídias
    if (videoCount + newVideoCount > 1 || (videoCount + newVideoCount > 0 && otherMediaCount + newOtherMediaCount > 0)) {
      setErro("Você não pode fazer upload de mais de um vídeo ou combinar vídeos com outras mídias.");
      fileInputRef.current.value = "";  // Limpa a seleção de arquivos
      return;
    }
  
  
    const newMidias = [];
  
    for (let file of files) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      console.log(`Arquivo recebido: ${file.name}, tipo: ${file.type}, extensão: ${fileExtension}`);
  
      if (["mpg", "mpeg", "mp1", "mp2", "wmv", "mkv", "webm", "avi", "mov", "mp4"].includes(fileExtension)) {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        await new Promise((resolve) => {
          video.onloadedmetadata = () => {
            const duration = video.duration;
            if (duration > 600) {
              setErro(`O vídeo "${file.name}" excede o limite de 10 minutos.`);
              console.error(`O vídeo "${file.name}" excede o limite de 10 minutos.`);
            } else {
              newMidias.push(file);
            }
            resolve();
          };
        });
      } else if (["jpg", "jpeg", "png", "webp"].includes(fileExtension)) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          convertToWebp: true,
        };
  
        try {
          const compressedFile = await imageCompression(file, options);
          const newFile = new File([compressedFile], file.name.replace(/\.\w+$/, '.webp'), { type: 'image/webp' });
          console.log(`Imagem comprimida: nome original: ${file.name}, novo nome: ${newFile.name}`);
          newMidias.push(newFile);
        } catch (error) {
          console.error("Erro ao comprimir a imagem:", error);
        }
      } else {
        setErro(`Tipo de arquivo não suportado: ${file.name}`);
        console.error(`Tipo de arquivo não suportado: ${file.name}`);
      }
    }
  
    setMidias(prevMidias => [...prevMidias, ...newMidias]);
    fileInputRef.current.value = "";
  };

  const handleRemoveMedia = (index) => {
    setMidias(midias.filter((_, i) => i !== index));
  };

  const handleViewMedia = (index) => {
    setMidiaAberta(index);
  };

  const handleClose = () => {
    setMidiaAberta(null);
  };

  const renderPreview = (index) => {
    const file = midias[index];
    const isVideo = file.type.startsWith("video");

    return (
      <div key={index} className="preview-file" style={{ position: 'relative', width: '200px', height: '200px' }}>
        {isVideo ? (
          <video className="b5"
            src={URL.createObjectURL(file)}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            muted
          />
        ) : (
          <img
            src={URL.createObjectURL(file)}
            alt="preview"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        )}
        <div className="overlay">
          <button type="button" className="btn-icon" onClick={() => handleRemoveMedia(index)}>
            <img src={trashIcon} alt="Remover" />
          </button>
          <button type="button" className="btn-icon" onClick={() => handleViewMedia(index)}>
            <img src={openIcon} alt="Visualizar" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flexcolumn">
      <label className="titulom" htmlFor="Midias">Mídias:</label>
      <div className="previamidias">
        <label className="custom-file-button" htmlFor="midias">
          <img className="custom-file-icon" src={moreIcon} alt="Ícone" />
        </label>
        <input
          type="file"
          id="midias"
          name="midias"
          accept=".webp,.png,.jpg,.jpeg,.mpg,.mpeg,.mp1,.mp2,.wmv,.mkv,.webm,.avi,.mov,.mp4"
          onChange={handleMidiasChange}
          multiple
          className="custom-file-input"
          ref={fileInputRef}
        />
        {midias.map((file, index) => renderPreview(index))}
      </div>
      <div className="midias-preview"></div>

      {midiaAberta !== null && (
        <div className='popup_midias'>
          {midias[midiaAberta].type.startsWith("video") ? (
            <video className="videofull"
              src={`${URL.createObjectURL(midias[midiaAberta])}`}
              controls
            />
          ) : (
            <img className='imagefull'
              src={`${URL.createObjectURL(midias[midiaAberta])}`}
              alt={`Conteúdo ${midiaAberta + 1}`}
              style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
            />
          )}
          <div className='flexrowcenter'>
            <button type="button" className='btn' onClick={handleClose} style={{ float: 'right' }}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaManager;
