import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./css/ea.css";
import PerfilRankingMin from "./components/PerfilRankingMin";
import ListaPosts from "./components/ListaPosts";
import ListaPostsFeitos from "./components/ListaPostsFeitos";
import Ajustes from "./components/AjustesC";
import Cookies from 'js-cookie';
import Produtos from "./components/Produtos";
import noprofile from "./assets/img/blankprofile.webp";
import axios from 'axios';
import useLogout from './useLogout';
import { ReactComponent as SettingsIcon } from './assets/img/settings.svg';
import { ReactComponent as ConteudoIcon } from './assets/img/conteudo.svg';
import sair from "./assets/img/sair.svg";
import ConfirmEmail from "./CofirmEmail";
import Suporte from "./Suporte";





const DashboardColab = () => {
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [colaboradores, setColaboradores] = useState([]); // Estado para armazenar os colaboradores
  const [selectedComponent, setSelectedComponent] = useState("Conteudos");
  const [selectedButton, setSelectedButton] = useState("Conteudos");
  const [loading, setLoading] = useState(true);
  const handleLogout = useLogout();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');


  const [funcionarioData, setFuncionarioData] = useState({
    nome: Cookies.get('funcionarioNome'),
    email: Cookies.get('funcionarioEmail'),
    fotoPerfilColab: Cookies.get('funcionarioFotoPerfilColab'),
    linkedin: Cookies.get('funcionarioLinkedin'),
    cargo: Cookies.get('funcionarioCargo'),
    dataAdmissao: Cookies.get('funcionarioDataAdmissao'),
    idade: Cookies.get('funcionarioIdade'),
    genero: Cookies.get('funcionarioGenero'),
    ups: Cookies.get('funcionarioUPS')
  });



  // Função para buscar os colaboradores ordenados por ranking da empresa
  const fetchRanking = async () => {
    try {
      const token = Cookies.get("token"); // Verifica o token de autenticação
      const empresaId = Cookies.get("empresaId"); // Obtenha o ID da empresa dos cookies

      if (!token) {
        throw new Error("Usuário não autenticado");
      }

      if (!empresaId) {
        throw new Error("ID da empresa não especificado");
      }

      console.log("Token obtido dos cookies:", token);
      console.log("ID da empresa obtido dos cookies:", empresaId);

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/rankingColaboradores`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          empresaId: empresaId
        }
      });
      setColaboradores(response.data);
    } catch (error) {
      console.error("Erro ao buscar ranking de colaboradores:", error);
    }
  };


  useEffect(() => {
    const emailConfirmed = Cookies.get('emailConfirmed');
    console.log('emailConfirmed set to:', emailConfirmed);
    console.log('Valor do cookie emailConfirmed:', emailConfirmed);
    setShowConfirmEmail(emailConfirmed === 'false');

    // Verificar se os cookies necessários estão presentes
    const funcionarioId = Cookies.get('funcionarioId');
    
    const empresaId = Cookies.get('empresaId');

    // Se não houver ID do funcionário ou da empresa nos cookies, redirecionar para a tela inicial
    if (!funcionarioId || !empresaId) {
      navigate('/'); // Ajustar conforme a rota de login ou início apropriada
      return;
    }

    

 

    // Chame a função para buscar os dados do ranking
    fetchRanking()
      .then(() => setLoading(false)) // Defina o estado de carregamento para false quando os dados forem carregados
      .catch(error => console.error("Erro ao buscar dados:", error));
  }, [navigate]);








  const [empresaData, setEmpresaData] = useState({
    nome: Cookies.get('empresaNome'),
    endereco: Cookies.get('empresaEndereco'),
    adm_nome: Cookies.get('empresaadm_nome'),
    telefone: Cookies.get('empresaTelefone'),
    email: Cookies.get('empresaEmail'),
    logo: Cookies.get('empresaLogo')
  });

  const handleButtonClick = (componentName) => {
    setSelectedComponent(componentName);
    setSelectedButton(componentName);
  };


  // Função para salvar o token no servidor
  const saveToken = async (token) => {
    try {
      // Criar um objeto FormData
      const formData = new FormData();

      // Adicionar o token ao FormData
      formData.append('token', token);

      // Obter o ID do funcionário dos cookies e adicionar ao FormData
      const funcionarioId = Cookies.get('funcionarioId');
      formData.append('funcionarioId', funcionarioId);

      // Logar o ID do funcionário e o token antes de enviar
      console.log("Enviando token e ID do funcionário para o servidor");
      console.log("Funcionario ID:", funcionarioId);
      console.log("Token:", token);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/salvar-token`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log("Resposta da API:", response.data);
    } catch (error) {
      console.error("Erro ao salvar token:", error);
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Detalhes do erro:", error.response.data.error);
      } else {
        console.error("Erro ao salvar token. Por favor, tente novamente mais tarde.");
      }
    } finally {
      // Apagar o token do localStorage independentemente do resultado da requisição
      localStorage.removeItem('linkedinToken');
      console.log('Token removed from localStorage');
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      console.log('Received token:', token);
      localStorage.setItem('linkedinToken', token);
      console.log('Token stored in localStorage:', localStorage.getItem('linkedinToken'));

      // Chama a função saveToken para enviar o token ao servidor
      saveToken(token).then(() => {
        console.log('Definindo token como válido');
        Cookies.set('isValidToken', true);
        setModalMessage('LinkedIn conectado com sucesso! Agora você já pode compartilhar posts.');
        setShowModal(true);
        // Substituir a entrada no histórico para remover o token da URL
        navigate('/DashboardColab', { replace: true });
      }).catch(() => {
        setModalMessage('Erro ao conectar LinkedIn!');
        setShowModal(true);
      });
    }


  }, [navigate]);






  return (
    <div className="mainfc">
      <Suporte />
      {/* <button onClick={handleAtualizar}>Atualizar</button>*/}
      {showConfirmEmail && <ConfirmEmail />}
      {showModal && (
        <div className="modal-background">
          <div className="modal2 centerflex">
            <h3>{modalMessage}</h3>
            <div>
              <button className="btn selected" onClick={() => setShowModal(false)}>Fechar</button>
            </div>
          </div>
        </div>
      )}
      <div className="esquerda">
        <div className="prevprofile">
          <div className="flexcolumn">
            <div className="flexrow">
              <div className="fotoperfil">
                {funcionarioData ? (
                  <img
                    className="w100p border100"
                    src={funcionarioData.fotoPerfilColab ? `${process.env.REACT_APP_API_URL}/${funcionarioData.fotoPerfilColab}` : noprofile}
                    alt="Imagem de Perfil"
                  />
                ) : (
                  <img
                    className="w100p border100"
                    src={noprofile}
                    alt="Sem imagem de perfil"
                  />
                )}
              </div>
              <div className="dadosperfil">
                <span className="fontnome">{funcionarioData.nome}</span>
                <span className="ups" >UPS {funcionarioData.ups}</span>

                <span>Empresa: {empresaData.nome}</span>

              </div>
            </div>

            <div className="btns-perfil">


              <div
                className={`btn2  ${selectedButton === "Conteudos" ? "selected" : ""}`}
                onClick={() => handleButtonClick("Conteudos")}
              >
                <ConteudoIcon className="icon" />
                <span>Conteúdos</span>
              </div>




              <div className="flexrow"  >
                <div
                  className={`btn2  ${selectedButton === "Ajustes" ? "selected" : ""}`}
                  onClick={() => handleButtonClick("Ajustes")}
                >
                  <SettingsIcon className="icon" />
                  <span>Ajustes</span>
                </div>




                <div className="btn2 w30" onClick={handleLogout}>
                  <img src={sair} className="icon" />
                  <span>Sair</span>
                </div>
              </div>
              <div
                className={`btn2  ${selectedButton === "Publicados" ? "selected" : ""}`}
                onClick={() => handleButtonClick("Publicados")}
              >
                <ConteudoIcon className="icon" />
                <span>Publicados</span>
              </div>
              <div
                className={`btn2  ${selectedButton === "Produtos" ? "selected" : ""}`}
                onClick={() => handleButtonClick("Produtos")}
              >
                <ConteudoIcon className="icon" />
                <span className="icon_text" >Loja</span>
              </div>

            </div>
          </div>
        </div>

        <div className="recentes">
          <div className="tandline">
            <h2>UPS</h2>
            <div className="line"></div>
          </div>
          <div className="recentes">
            {colaboradores.slice(0, 6).map((colaborador, index) => (
              <PerfilRankingMin
                key={colaborador.IdFuncionario}
                ranking={index + 1}
                nome={colaborador.nome}
                ups={colaborador.ups}
                fotoPerfilColab={colaborador.fotoPerfilColab}
              />
            ))}
          </div>
        </div>

      </div>

      <div className="linhadiv"></div>

      <div className="direita">
        {selectedComponent === "Conteudos" && <ListaPosts setFuncionarioData={setFuncionarioData} />}
        {selectedComponent === "Publicados" && <ListaPostsFeitos />}
        {selectedComponent === "Ajustes" && <Ajustes />}
        {selectedComponent === "Produtos" && <Produtos />}
      </div>



    </div>



  );
};

export default DashboardColab;
