import React, { useState, useEffect } from "react";
import "../css/ea.css";
import ListaEmpresas from "./ListaEmpresas";
import PerfilCompletoEmpresa from "./PerfilCompletoEmpresa";

const Empresas = () => {
  const [selectedComponent, setSelectedComponent] = useState("Empresas");
  const [selectedButton, setSelectedButton] = useState("Empresas");
  const [perfilCompleto, setPerfilCompleto] = useState(null);

  useEffect(() => {
    setSelectedComponent("Empresas");
    setSelectedButton("Empresas");
  }, []);

  const handleButtonClick = (componentName) => {
    setSelectedComponent(componentName);
    setSelectedButton(componentName);
  };

  const handleVerMais = (empresa) => {
    setPerfilCompleto(empresa);
  };

  const renderSelectedComponent = () => {
    if (perfilCompleto) {
      return (
        <PerfilCompletoEmpresa
          empresa={perfilCompleto}
          onClose={() => setPerfilCompleto(null)}
        />
      );
    }
    return <ListaEmpresas handleVerMais={handleVerMais} />;
  };

  return (
    <div className="w100">
      <div className="direitaopcs">
        <div className="ranking-conteudos">
          {renderSelectedComponent()}
        </div>
        <div className="flexcolumn">
          <div
            className={`btn ${selectedButton === "Empresas" ? "selected" : ""}`}
            onClick={() => handleButtonClick("Empresas")}
          >
            <span>Empresas</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Empresas;
