import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import './css/ea.css';

const EscolherPlano = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cadastroSucesso = location.state?.cadastroSucesso;
  const empresaIdFromState = location.state?.empresaId;
  const token = Cookies.get('token');

  const [empresaId, setEmpresaId] = useState(empresaIdFromState || Cookies.get('empresaId'));
  const [isSemestral, setIsSemestral] = useState(true);
  const [planoAtivo, setPlanoAtivo] = useState(null); // Estado para o plano ativo

  useEffect(() => {
    // Log para verificar o token e empresaId recebidos
    console.log("Token recebido:", token);
    console.log("empresaId recebido:", empresaId);
    
    // Verificação inicial se o token ou empresaId estão ausentes
    if (!empresaIdFromState && (!token || !empresaId)) {
      console.log("Token ou empresaId ausente. Redirecionando para login...");
      navigate('/?redirect=/escolherplano');
      return; // Sai do useEffect se as verificações falharem
    }

    // Verificar o plano ativo com base no cookie plano_nome
    const planoNome = Cookies.get('plano_nome');
    if (planoNome && planoNome !== 'experience') {
      setPlanoAtivo(planoNome); // Define o plano ativo se for diferente de 'experience'
    }
  }, [empresaIdFromState, token, empresaId, navigate]);

  const handleToggleSemestral = () => setIsSemestral(true);
  const handleToggleAnual = () => setIsSemestral(false);

  const handleCheckout = (priceId) => {
    // Log para verificar os IDs antes de navegar
    console.log('Navegando para a página de pagamento com:', { priceId, empresaId });
  
    // Redireciona para a página de pagamento com o priceId
    navigate('/PaymentPage', { state: { priceId, empresaId } });
  };
  
  return (
    <div className="maincenter100 plan-container">
      {planoAtivo ? (
        <div className="success-message flex_column">
          <h1>Você já possui um plano ativo!</h1>
          <p>Está precisando de ajuda com o plano?</p>
          <a href="mailto:suporte@rise.app.br"><button class="font14 btn gray">suporte@rise.app.br</button></a>
          <a href="/"><button class="font14 btn gray">Voltar</button></a>
        </div>
      ) : (
        // Renderiza a interface para escolher planos apenas se não houver um plano ativo
        <>
          {cadastroSucesso ? (
            <div className="success-message">
              <h1>Cadastro Feito Com Sucesso!</h1>
              <h2>Escolha um Plano</h2>
              <div className="jcenter tandline">
                <div className="line300"></div>
              </div>
            </div>
          ) : (
            <div className="success-message">
              <h1>Escolher um Plano</h1>
              <div className="jcenter tandline">
                <div className="line300"></div>
              </div>
            </div>
          )}
          <div className="toggle-container">
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="toggleSemestral"
                checked={isSemestral}
                onChange={handleToggleSemestral}
                className="custom-switch"
              />
              <label className="label1" htmlFor="toggleSemestral">Semestral</label>
            </div>
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="toggleAnual"
                checked={!isSemestral}
                onChange={handleToggleAnual}
                className="custom-switch"
              />
              <label className="label1" htmlFor="toggleAnual">Anual</label>
            </div>
          </div>
          <div className="plan-cards max_wd">
            <div className="plan-card">
              <div className='flexcolumnleft'>
                <p className='planame'>Experience</p>
                <p className='planprice'>R$ 0,00<span className='plansuf'> p/ mês</span></p>
                <p className='plandesc'>Gratuito para Sempre</p>
              </div>
              <ul>
                <li>Até 07 Usuários</li>
                <li>Perfil de Adm</li>
                <li>Suporte RISE! por Email</li>
                <li>Acesso a Funcionalidades Limitadas</li>
                <li>Integração com o LinkedIn</li>
                <li>até 25 posts/mês</li>
              </ul>
              <button onClick={() => window.location.href = '/DashboardEmpresa'}>Continuar com Este</button>
            </div> 
  
            <div className="plan-card">
              <div className='flexcolumnleft'>
                <p className='planame'>Expert</p>
                <p className='planprice'>R$ {isSemestral ? '8,20' : '7,20'}<span className='plansuf'> p/ mês</span></p>
                <p className='plandesc'>{isSemestral ? 'Cobrado uma vez R$ 688,80' : 'Cobrado uma vez R$ 1.209,60'}</p>
              </div>
              <ul>
                <li>até 14 Usuários</li>
                <li>Perfil de ADM</li>
                <li>Suporte RISE!</li>
                <li>Integração com o LinkedIn</li>
                <li>Ranking de Colaboradores</li>
                <li>Onboarding com Equipe RISE</li>
                <li>Acompanhamento com a Equipe RISE</li>
                <li>Gerente de Contas Dedicado</li>  
                <li>Relatórios Mensais</li>
                <li>até 50 posts/mês</li>
              </ul>
              <button onClick={() => handleCheckout(isSemestral ? 'price_1PiGyVRtR7QBHZGfalbbrWo1' : 'price_1Pqb1sRtR7QBHZGfzywjBopM')}>
                Contratar Agora
              </button>
            </div>
  
            <div className="plan-card">
              <div className='flexcolumnleft'>
                <p className='planame'>Enterprise</p>
                <p className='planprice'>Consulte Com Nossa Equipe</p>
              </div>
              <ul>
                <li>Usuários ilimitados</li>
                <li>Perfil de ADM</li>
                <li>Acesso a todas as funcionalidades</li>
                <li>Ranking de Colaboradores</li>
                <li>Suporte RISE!</li>
                <li>Acesso a todas Funcionalidades</li>
                <li>Integração com LinkedIn</li>
                <li>Onboarding com a Equipe RISE!</li>
                <li>Acompanhamento com a Equipe RISE!</li>
                <li>Gerente de Contas Dedicado</li>
                <li>Relatórios Mensais</li>
                <li>Publicações ilimitadas</li>
              </ul>

              <button onClick={() => window.location.href = 'https://wa.me/5531989700738'}>Fale Conosco</button>

             
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EscolherPlano;
