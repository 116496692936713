import React from "react";
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp";

const PerfilRankingMin = ({ ranking, nome, ups, fotoPerfilColab }) => {
  return (
    <div className="postr">
      <div className="imagepr">
        <div className="profile-pink">
          <div className="profile-pink-detalhe">
            <span className="ranking-nv">{ranking}</span>
          </div>
          <img
            className="wid60 border100"
            src={fotoPerfilColab || noprofile}
            alt="Imagem de Perfil"
          />
        </div>
      </div>
      <div className="contentpr">
        <span className="titpostr">{ups} | {nome}</span>
      </div>
    </div>
  );
};

export default PerfilRankingMin;
