import React, { useState, useRef } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import "./css/ea.css";
import more from "./assets/img/more.svg";
import removeicon from "./assets/img/remove.svg";

const CadastrarColabComCodigo = () => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    senha: "",
    confirmarSenha: "",
    codigoEmpresa: "",
  });
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [cadastroSucesso, setCadastroSucesso] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento
  const [mostrarFormulario, setMostrarFormulario] = useState(true); // Novo estado
  const editorRef = useRef(null);

  const onDrop = (acceptedFiles) => {
    console.log("Arquivo recebido:", acceptedFiles[0]);
    setFotoPerfil(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleZoomChange = (e) => {
    setZoom(parseFloat(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Iniciar carregamento
    console.log("Enviando formulário");

    const formDataToSend = new FormData();

    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    const editor = editorRef.current;
    if (editor && fotoPerfil) {
      console.log("Obtendo imagem do editor");
      const canvasScaled = editor.getImageScaledToCanvas();
      canvasScaled.toBlob(async (blob) => {
        console.log("Convertendo imagem para Blob");
        formDataToSend.append("fotoPerfil", blob, `fotoPerfil.webp`);
        await sendFormData(formDataToSend);
      }, "image/webp", 0.9);
    } else {
      await sendFormData(formDataToSend);
    }
  };

  const sendFormData = async (formData) => {
    try {
      console.log("Enviando dados para o servidor");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cadastrar-colaborador-com-codigo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Resposta da API:", response.data);
      setFormData({
        nome: "",
        email: "",
        senha: "",
        confirmarSenha: "",
        codigoEmpresa: "",
      });
      setFotoPerfil(null);
      setZoom(1);
      setErrorMessage("");
      setCadastroSucesso(true);
      setMostrarFormulario(true); // Certifique-se de que o formulário está visível após um sucesso
    } catch (error) {
      console.error("Erro ao cadastrar colaborador:", error);
      if (error.response && error.response.data && error.response.data.error) {
        const mensagemErro = error.response.data.error;
        setErrorMessage(mensagemErro);
        if (mensagemErro.includes("Limite de colaboradores excedido")) {
          setMostrarFormulario(false); // Esconde o formulário se o limite for excedido
        }
      } else {
        setErrorMessage("Erro ao cadastrar colaborador. Por favor, tente novamente mais tarde.");
      }
    } finally {
      setIsLoading(false); // Finalizar carregamento
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleVoltar = () => {
    window.location.href = "/";
  };

  if (cadastroSucesso) {
    return (
      <div className="maincenter100">
        <div className="flexcolumn center30">
          <h3 className="bold">Cadastro bem-sucedido!</h3>
          <button className="botao1" onClick={handleVoltar}>Login</button>
          <span className="stext">Um e-mail de confirmação foi enviado para o e-mail cadastrado, acesse seu e-mail e clique no link de confirmação.</span>
        </div>
      </div>
    );
  }

  return (
    <div className="maincenter100">
      <div className="flexcolumn max60">
        <div className="tandline">
          <h2>Cadastrar Colaborador</h2>
          <div className="line300"></div>
        </div>

        {errorMessage && errorMessage.includes("Limite de colaboradores excedido") && (
          <div className="legenda">
            O limite de colaboradores da empresa foi excedido. Por favor, notifique o administrador para alterar o plano.
          </div>
        )}

        {isLoading ? (
          <div className="loading-container2">
            <div className="loading-spinner2"></div>
          </div>
        ) : (
          mostrarFormulario && ( // Condicional para mostrar ou esconder o formulário
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="mb15">
                {!fotoPerfil && (
                  <div {...getRootProps()} className="border100 custom-file-button">
                    <input {...getInputProps()} />
                    <img className="custom-file-icon" src={more} alt="Selecionar Imagem" />
                  </div>
                )}

                {fotoPerfil && (
                  <div className="flexcolumn">
                    <div className="flexrow">
                      <div className="perfil-avatar">
                        <AvatarEditor
                          ref={editorRef}
                          image={URL.createObjectURL(fotoPerfil)}
                          width={200}
                          height={200}
                          border={0}
                          borderRadius={125}
                          color={[255, 255, 255, 255]}
                          scale={zoom}
                          rotate={0}
                          backgroundColor={"transparent"}
                        />
                      </div>
                      <div>
                        <div className="btn-red" onClick={() => setFotoPerfil(null)}>
                          <img className="wid12" src={removeicon} alt="Remover Foto de Perfil" />
                          <span>Remover Foto de Perfil</span>
                        </div>
                        <input
                          type="range"
                          min="1"
                          max="2"
                          step="0.01"
                          value={zoom}
                          onChange={handleZoomChange}
                          className="zoom-slider"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="form-container gap10">
                {Object.entries(formData).map(([key, value], index) => (
                  <div className={`form-field left_t ${index < 2 ? 'first-row' : 'second-row'}`} key={key}>
                    <div>
                      <label htmlFor={key}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                      </label>
                      <input
                        type={
                          key.includes("senha") || key.includes("confirmarSenha")
                            ? "password"
                            : "text"
                        }
                        id={key}
                        name={key}
                        value={value}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="form-submit">
                <button type="submit" className=" m10top btn gray btn-submit">
                  Salvar
                </button>
              </div>
            </form>
          )
        )}

        {errorMessage && !errorMessage.includes("Limite de colaboradores excedido") && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default CadastrarColabComCodigo;
