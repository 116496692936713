import React, { useState, useEffect } from "react";
import axios from 'axios';
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp";
import ListaColaboradores from "./ListaColaboradores";
import CadastrarColab from "./CadastrarColab";
import PerfilCompletoColab from "./PerfilCompletoColab";


const Colaboradores = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  useEffect(() => {
    setSelectedComponent("Colaboradores");
    setSelectedButton("Colaboradores");
  }, []);

  const handleButtonClick = (componentName) => {
    setSelectedComponent(componentName);
    setSelectedButton(componentName);
  };

  const handleVerMais = (colaborador) => {
    setPopupContent(colaborador);
    setShowPopup(true);
  };

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "Colaboradores":
        return <ListaColaboradores handleVerMais={handleVerMais} />;
      case "CadastrarColab":
        return <CadastrarColab />;
      
      default:
        return null;
    }
  };

  return (
    <div className="w100">
      <div className="direitaopcs">
        <div className="ranking-conteudos">
          {renderSelectedComponent()}
        </div>

        <div className="flexcolumn">
          <div
            className={`btn ${selectedButton === "Colaboradores" ? "selected" : ""}`}
            onClick={() => handleButtonClick("Colaboradores")}
          >
            <span>Colaboradores</span>
          </div>

          <div
            className={`btn ${selectedButton === "CadastrarColab" ? "selected" : ""}`}
            onClick={() => handleButtonClick("CadastrarColab")}
          >
            <span>Cadastrar Novo</span>
          </div>


         
        </div>
      </div>

      {showPopup && (
        <PerfilCompletoColab 
          colaborador={popupContent} 
          onClose={() => setShowPopup(false)} 
        />
      )}
    </div>
  );
};

export default Colaboradores;
