import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp"; // Imagem de fallback

const GerenciarTrocas = () => {
  const [trocas, setTrocas] = useState([]);
  const [loading, setLoading] = useState(true);
  const empresaId = Cookies.get("empresaId");
  const token = Cookies.get("token");

  const fetchTrocasEmpresa = async () => {
    setLoading(true);
    try {
      if (!empresaId || !token) {
        throw new Error("Usuário não autenticado");
      }

      // Nova rota para buscar trocas da empresa
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/gerenciarTrocas/${empresaId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setTrocas(response.data.trocas);
    } catch (error) {
      console.error("Erro ao buscar trocas da empresa:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrocasEmpresa();
  }, []);

  // Função para alterar o status de uma troca (implementação futura)
  const alterarStatusTroca = async (trocaId, novoStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/gerenciarTroca/${trocaId}/status`,
        { status: novoStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Atualizar a troca localmente após a alteração de status
      setTrocas((prevTrocas) =>
        prevTrocas.map((troca) =>
          troca.id === trocaId ? { ...troca, status: novoStatus } : troca
        )
      );
      console.log("Status atualizado com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao alterar o status da troca:", error);
    }
  };

  return (
    <div>
      <div className="tandline">
        <h2>Gerenciar Trocas</h2>
        <div className="line300"></div>
      </div>

      {loading ? (
        <p>Carregando...</p>
      ) : (
        <div className="trocas-grid">
          {trocas.length === 0 ? (
            <p>Nenhuma troca realizada.</p>
          ) : (
            trocas.map((troca, index) => (
              <div key={index} className="historico-troca-card">
                <img
                  src={troca.imagem ? `${process.env.REACT_APP_API_URL}/${troca.imagem}` : noprofile}
                  alt={troca.produtoNome || "Produto sem imagem"}
                />
                <div className="historico-troca-info">
                  <h3>{troca.produtoNome}</h3>
                  <p>Funcionário: {troca.Funcionario ? troca.Funcionario.nome : "Funcionário não encontrado"}</p>

                  <p>Data: {new Date(troca.data).toLocaleDateString()}</p>
                  <p className={`historico-troca-status ${troca.status.toLowerCase()}`}>
                    Status: {troca.status}
                  </p>

                  {/* Botões para alterar o status da troca */}
                  <div className="troca-actions">
                    <button className="btn2  selected btn_s" onClick={() => alterarStatusTroca(troca.id, "entregue")}>
                      Marcar como Entregue
                    </button>
               
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default GerenciarTrocas;
