import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import "../css/ea.css";
import more from "../assets/img/more.svg";
import removeicon from "../assets/img/remove.svg";
import Cookies from "js-cookie"; // Importa o pacote de cookies

const CadastrarColab = () => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    cargo: "",
  });
  const [fotoPerfilColab, setFotoPerfilColab] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [empresaId, setEmpresaId] = useState(""); // Estado para armazenar o ID da empresa
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const editorRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [cadastroSucesso, setCadastroSucesso] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(true); // Novo estado

  useEffect(() => {
    // Obtém o ID da empresa armazenado nos cookies
    const id = Cookies.get("empresaId");
    setEmpresaId(id);
  }, []); // Executa somente uma vez após o componente ser montado

  const onDrop = (acceptedFiles) => {
    setFotoPerfilColab(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleZoomChange = (e) => {
    setZoom(parseFloat(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Inicia o carregamento
    console.log("Enviando formulário");

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("empresaId", empresaId); // Adiciona o ID da empresa ao formulário

    const editor = editorRef.current;
    if (editor && fotoPerfilColab) {
      console.log("Obtendo imagem do editor");
      const canvasScaled = editor.getImageScaledToCanvas();
      canvasScaled.toBlob(async (blob) => {
        console.log("Convertendo imagem para Blob");
        formDataToSend.append("fotoPerfilColab", blob, `fotoPerfil.webp`);
        await sendFormData(formDataToSend);
      }, "image/webp", 0.9);
    } else {
      await sendFormData(formDataToSend);
    }
  };

  const sendFormData = async (formDataToSend) => {
    try {
      console.log("Enviando dados para o servidor");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cadastrar-funcionario`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Resposta da API:", response.data);
      setFormData({
        nome: "",
        email: "",
        cargo: "",
      });
      setFotoPerfilColab(null);
      setZoom(1);
      setErrorMessage("");
      setCadastroSucesso(true);
      setMostrarFormulario(true); // Certifique-se de que o formulário está visível após um sucesso
    } catch (error) {
      console.error("Erro ao cadastrar colaborador:", error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
        if (error.response.data.error.includes("Limite de colaboradores excedido")) {
          setMostrarFormulario(false); // Esconde o formulário se o limite for excedido
        }
      } else {
        setErrorMessage("Erro ao cadastrar colaborador. Por favor, tente novamente mais tarde.");
      }
    } finally {
      setLoading(false); // Termina o carregamento
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleVoltar = () => {
    window.location.href = "/";
  };

  if (cadastroSucesso) {
    return (
      <div className="flexcolumn">
        <div className="tandline">
          <h2>Cadastrar Colaborador</h2>
          <div className="line300"></div>
        </div>
        <span className="stext legenda">Enviamos um e-mail para definição de senha ao colaborador.</span>
        <button className="btn btn-primary" onClick={() => setCadastroSucesso(false)}>
          Cadastrar Outro
        </button>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flexcolumn">
        <div className="tandline">
          <h2>Cadastrar Colaborador</h2>
          <div className="line300"></div>
        </div>

        {errorMessage && (
          <div className="error-message flexcolumn">
            {errorMessage}
            {errorMessage.includes("Limite de colaboradores excedido") && (
              <div>
                <button
                  className="btn grafite large_button"
                  onClick={() => window.location.href = "/EscolherPlano"}
                >
                  Alterar Plano
                </button>
              </div>
            )}
          </div>
        )}

        {loading ? (
          <div className="loading-container2">
            <div className="loading-spinner2"></div>
          </div>
        ) : (
          mostrarFormulario && ( // Condicional para mostrar ou esconder o formulário
            <form className="flexcolumn" onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="mb15">
                {!fotoPerfilColab && (
                  <div {...getRootProps()} className="border100 custom-file-button">
                    <input {...getInputProps()} />
                    <img className="custom-file-icon" src={more} alt="Selecionar Imagem" />
                  </div>
                )}

                {fotoPerfilColab && (
                  <div className="flexcolumn">
                    <div className="flexrow">
                      <div className="perfil-avatar">
                        <AvatarEditor
                          ref={editorRef}
                          image={URL.createObjectURL(fotoPerfilColab)}
                          width={200}
                          height={200}
                          border={0}
                          borderRadius={125}
                          color={[255, 255, 255, 255]}
                          scale={zoom}
                          rotate={0}
                          backgroundColor={"transparent"}
                        />
                      </div>
                      <div>
                        <div className="btn-red" onClick={() => setFotoPerfilColab(null)}>
                          <img className="wid12" src={removeicon} alt="Remover Foto de Perfil" />
                        </div>
                        <input
                          type="range"
                          min="1"
                          max="2"
                          step="0.01"
                          value={zoom}
                          onChange={handleZoomChange}
                          className="zoom-slider"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {Object.entries(formData).map(([key, value]) => (
                <div key={key} className=" left_t form-field">
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input 
                    type={key.includes("senha") || key.includes("confirmarSenha") ? "password" : "text"}
                    id={key}
                    name={key}
                    value={value}
                    onChange={handleChange}
                    required
                  />
                </div>
              ))}

              <div className="form-submit">
                <button type="submit" className="btn-black">
                  <span>Salvar</span>
                </button>
              </div>
            </form>
          )
        )}
      </div>
    </div>
  );
};

export default CadastrarColab;
