import React, { useState } from 'react';
import axios from "axios";
import Cookies from "js-cookie";

const ConfirmEmail = () => {
  const [buttonText, setButtonText] = useState('Reenviar');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleReenviar = () => {
    const entityType = Cookies.get('entityType');
    let id, email;

    if (entityType === 'empresa') {
      id = Cookies.get('empresaId');
      email = Cookies.get('empresaEmail');
    } else if (entityType === 'funcionario') {
      id = Cookies.get('funcionarioId');
      email = Cookies.get('funcionarioEmail');
    }

    if (id && email) {
      console.log('Reenviando email para:', email);
      setButtonDisabled(true);
      axios.post(`${process.env.REACT_APP_API_URL}/enviar-confirmacao-email`, { email, id })
        .then(response => {
          console.log('Email reenviado com sucesso!');
          setButtonText('Enviado!');
        })
        .catch(error => {
          console.log('Erro ao reenviar email', error);
          setButtonText('Erro ao enviar');
        });
    } else {
      console.log('Dados necessários para reenvio não disponíveis.');
    }
  };

  return (
    <div className='avisotopo'>
      <p>Seu email não foi confirmado. Confirme com o link que recebeu no e-mail. Caso não tenha recebido, clique em <button className='btnmin' onClick={handleReenviar} disabled={buttonDisabled} style={{ backgroundColor: buttonDisabled ? '#dfdfde' : '', cursor: buttonDisabled ? 'not-allowed' : 'pointer' }}>{buttonText}</button></p>
    </div>
  );
}

export default ConfirmEmail;
