import { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';

import { setFuncionarioData } from '../DashboardColab';


const ConteudoCompleto = ({ post, onVoltar, onLoadPosts, setFuncionarioData }) => {

  const isValidToken = Cookies.get('isValidToken') === 'true'; // Verifica se o cookie isValidToken é true
  const [imagemAberta, setImagemAberta] = useState(null);
  const [urlsImagens, setUrlsImagens] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const isUrlInvalid = !urlsImagens.length || urlsImagens.includes('https://painel.rise.app.br/');
  const [errorMessage, setErrorMessage] = useState('');
  const usertype = Cookies.get('entityType');
  const isTokenValid = Cookies.get('linkedin_token_valid') === 'true';
  const [linkedinPostURL, setLinkedinPostURL] = useState(''); // Estado para armazenar a URL do post
  const [contagem, setContagem] = useState(5);
  const [isShared, setIsShared] = useState(false);
  const textareaRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(false);


  const [botaoDesabilitado, setBotaoDesabilitado] = useState(true);
  const [legendaEditada, setLegendaEditada] = useState(
    post.legendasEditadas && post.legendasEditadas.length > 0
      ? post.legendasEditadas[0].legendaAlterada
      : ''
  );

  const handleEditLegenda = () => {
    setLegendaEditada(legendasEditadas[0] || post.legenda);
    setIsEditMode(true);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };


  useEffect(() => {
    if (textareaRef.current && isEditMode) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [isEditMode, legendaEditada]);






  const [legendasEditadas, setLegendasEditadas] = useState(
    post.legendasEditadas && post.legendasEditadas.length > 0
      ? post.legendasEditadas.map(le => le.legendaAlterada)
      : [post.legenda] // Defina a legenda original se não houver legendas editadas
  );






  useEffect(() => {
    if (showSuccess) {
      setBotaoDesabilitado(true);
      setContagem(5);
      const interval = setInterval(() => {
        setContagem(prevContagem => {
          if (prevContagem <= 1) {
            clearInterval(interval);
            setBotaoDesabilitado(false);
            return 0;
          }
          return prevContagem - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [showSuccess]);


  // Função auxiliar para verificar se a URL é de um vídeo
  const isVideo = (url) => {
    return url.match(/\.(mp4|webm)$/i);
  };

  // Função para formatar a URL da imagem
  const formatImageUrl = (url) => {
    // Verifica se a URL original está vazia, indefinida ou é inválida
    if (!url || url.trim() === "" || url === "https://painel.rise.app.br/") {
      console.log("URL original está vazia, indefinida ou é inválida.");
      return null;
    }

    // Removendo as barras invertidas (\) e formatando a URL
    const panelBaseUrl = 'https://painel.rise.app.br/';
    const formattedUrl = `${panelBaseUrl}/${url.replace(/\\/g, '')}`;
    return formattedUrl;
  };


  const atualizarUpsColaborador = async (funcionarioId, upsPost) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/updateUps`, { funcionarioId, upsPost });
      console.log("Ups do colaborador atualizados com sucesso.");
    } catch (error) {
      console.error("Erro ao atualizar ups do colaborador:", error);
    }
  };



  useEffect(() => {
    if (post.midias) {
      const midiasLimpa = post.midias.replace(/[\[\]"\s]/g, '');
      const midiasArray = midiasLimpa.split(',').map(formatImageUrl).filter(url => url !== null);
      setUrlsImagens(midiasArray);
    }

    console.log("Post legendas editadas:", post.legendasEditadas);

    if (post.legendasEditadas && post.legendasEditadas.length > 0) {
      const legendas = post.legendasEditadas.map(le => le.legendaAlterada);
      setLegendasEditadas(legendas);

      // Verifica se alguma legenda editada foi compartilhada
      const sharedStatus = post.legendasEditadas.some(le => le.shared);
      setIsShared(sharedStatus);

      console.log("Legendas editadas configuradas:", legendas);
    } else {
      setLegendasEditadas([]); // Defina como um array vazio se não houver legendas editadas
    }
  }, [post.midias, post.legendasEditadas]);








  const formatarData = (dataString) => {
    const data = new Date(dataString);
    return data.toLocaleDateString('pt-BR');
  };

  const handlePrev = () => {
    setImagemAberta((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : urlsImagens.length - 1));
  };

  const handleNext = () => {
    setImagemAberta((prevIndex) => (prevIndex < urlsImagens.length - 1 ? prevIndex + 1 : 0));
  };

  const handleClose = () => {
    setImagemAberta(null);
  };

  const handleDelete = () => {
    setShowConfirmation(true);
  };

  const handleSaveLegenda = async () => {
    const funcionarioId = Cookies.get('funcionarioId');
    if (!funcionarioId) {
      console.error("Funcionário não autenticado ou ID não encontrado.");
      return;
    }

    const postBody = {
      funcionarioId: funcionarioId,
      postOriginalId: post.id,
      legendaAlterada: legendaEditada !== post.legenda ? legendaEditada : ''
    };

    console.log('Enviando dados ao servidor:', postBody);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/editarLegenda`, postBody, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log("Legenda alterada com sucesso.");

      // Atualizar a legenda editada no estado local
      setLegendasEditadas(prevLegendas => {
        const novasLegendas = [...prevLegendas];
        if (legendaEditada !== post.legenda) {
          if (novasLegendas.length > 0) {
            novasLegendas[0] = legendaEditada; // Substituir a primeira legenda editada se existir
          } else {
            novasLegendas.push(legendaEditada); // Adicionar a nova legenda se não existir
          }
        } else {
          if (novasLegendas.length > 0) {
            novasLegendas[0] = ''; // Se a legenda editada é igual à original, remover a edição
          }
        }
        return novasLegendas.filter(le => le !== ''); // Remover legendas vazias
      });

      setIsEditMode(false);
    } catch (error) {
      console.error("Erro ao alterar legenda:", error);
    }
  };

  const handleChange = (e) => {
    setLegendaEditada(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };







  const handleLogin = () => {
    const clientId = '77c2511i4lvitl';
    const redirectUri = encodeURIComponent(`${process.env.REACT_APP_API_URL}/oauth/linkedin/callback`);
    const scope = encodeURIComponent('openid profile w_member_social email');
    const state = Math.random().toString(36).substring(7);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
    window.location.href = url;
  };


  { console.log(`Token válido: ${isValidToken}`) }





  const handleShareLinkedIn = async () => {
    setLoading(true);
    setShowSuccess(false);
    setShowError(false);

    const funcionarioId = Cookies.get('funcionarioId');
    if (!funcionarioId) {
      console.error("Funcionário não autenticado ou ID não encontrado.");
      setLoading(false);
      return;
    }

    // Use a legenda editada se houver, caso contrário, use a legenda original
    const legendaParaCompartilhar = legendaEditada || post.legenda;

    const postBody = {
      funcionarioId: funcionarioId,
      internoPostId: post.id, // Incluindo o ID do post
      comment: legendaParaCompartilhar,
      midias: urlsImagens
    };

    console.log('Enviando dados ao servidor:', postBody);

    try {
      const proxyUrl = `${process.env.REACT_APP_API_URL}/proxy/linkedin`;
      const response = await axios.post(proxyUrl, postBody, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log("Post compartilhado no LinkedIn com sucesso:", response.data);
      await atualizarUpsColaborador(funcionarioId, post.ups);
      setShowSuccess(true);


      // Atualizar ups no cookie
      const upsAtuais = parseInt(Cookies.get('funcionarioUPS')) || 0;
      const novosUps = upsAtuais + post.ups;
      Cookies.set('funcionarioUPS', novosUps);

      // Atualizar funcionarioData em DashboardColab
      setFuncionarioData(prevData => ({
        ...prevData,
        ups: novosUps
      }));

      setLinkedinPostURL(response.data.linkedinPostURL);
    } catch (error) {
      console.error("Erro ao compartilhar no LinkedIn através do proxy:", error);
      setErrorMessage("O compartilhamento falhou. Por favor, tente novamente.");
      setShowError(true);
    }

    setLoading(false);
  };





  const confirmarExclusao = async () => {
    try {
      const email = Cookies.get('empresaEmail'); // Pegue o email dos cookies
      if (!email) {
        console.error('Email não encontrado nos cookies.');
        setErrorMessage('Email não encontrado. Por favor, faça login novamente.');
        setShowError(true);
        return;
      }

      await axios.delete(`${process.env.REACT_APP_API_URL}/deletePost/${post.id}`, {
        params: { email: email },
      });
      setShowConfirmation(false);
      onLoadPosts();
      onVoltar();
    } catch (error) {
      console.error('Erro ao excluir o post:', error);
      setErrorMessage('Erro ao excluir o post. Por favor, tente novamente.');
      setShowError(true);
    }
  };

  const hasMultipleImages = urlsImagens.length > 1;

  return (
    <div className='flexcolumn'>
      {loading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          {/* Se a ação foi bem-sucedida, exiba a mensagem de sucesso */}
          {showSuccess && (
            <div className="modal-background">
              <div className="modal">
                <h3 className='bold'>Sucesso</h3>
                <p>O Compartilhamento Foi Realizado com Sucesso!</p>
                <div className='flexrow'>
                  <button className='btn gray' onClick={() => setShowSuccess(false)}>Fechar</button>
                  <button
                    className={`btn gray ${botaoDesabilitado ? 'btn-disabled' : ''}`}
                    onClick={() => window.open(linkedinPostURL, '_blank')}
                    disabled={botaoDesabilitado}
                  >
                    Visualizar {botaoDesabilitado && `(${contagem})`}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="tandline">
            <h2>Contéudo</h2>
            <div className="line300"></div>
          </div>

          <p className="ups">+ {post.ups} UPS</p>




          <div className='flexcolumn'>

          {usertype === 'funcionario' && (
               <p className="textop">Original</p>
            )}
            
              

            {isEditMode && !legendasEditadas.length ? (
              <textarea
                type='text'
                ref={textareaRef}
                className='legenda m10top edit_l'
                value={legendaEditada}
                onChange={(e) => setLegendaEditada(e.target.value)}
              />
            ) : (
              <p className={`legenda ${legendasEditadas.length > 0 && legendasEditadas[0] !== post.legenda ? 'inative' : ''}`}>
                {post.legenda}
              </p>
            )}
            {legendasEditadas.length > 0 && legendasEditadas[0] !== post.legenda && (

              <div>
                <p className="textop ">Sua Versão</p>
                {isEditMode ? (
                  <textarea

                    type='text'
                    className='legenda m10top edit_l'
                    value={legendaEditada}
                    onChange={(e) => setLegendaEditada(e.target.value)}
                    ref={textareaRef}
                  />
                ) : (

                  <p className='m10top legenda'>
                    {legendasEditadas[0]}
                  </p>
                )}
              </div>
            )}
          </div>

          {usertype === 'funcionario' && !isShared && post.alteravel && (
            <>
              {isEditMode ? (
                <button className='btn' onClick={handleSaveLegenda}>Salvar</button>
              ) : (
                <button className='btn widfit' onClick={handleEditLegenda}>Editar</button>
              )}
            </>
          )}


          {!isUrlInvalid && (
            <div className='flexrow imagepostfull'>
              {urlsImagens.map((url, index) => (
                isVideo(url) ? (
                  <video key={index} controls style={{ maxWidth: '250px', width: '100%', height: 'auto', margin: '10px 0' }}>
                    <source src={url} type="video/mp4" />
                    Seu navegador não suporta vídeos.
                  </video>
                ) : (
                  <img
                    key={index}
                    src={url}
                    alt={`Conteúdo ${index + 1}`}
                    style={{ maxWidth: '250px', width: '100%', height: 'auto', margin: '10px 0', cursor: 'pointer' }}
                    onClick={() => setImagemAberta(index)}
                  />
                )
              ))}
            </div>
          )}

          {imagemAberta !== null && (
            <div className='popup_midias'>
              <img className='imagefull'
                src={`${urlsImagens[imagemAberta].replace(/\\/g, '')}`}
                alt={`Conteúdo ${imagemAberta + 1}`}
                style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
              />

              <div className='flexrowcenter'>
                {hasMultipleImages && (
                  <>
                    <button className='btn' onClick={handlePrev} style={{ marginRight: '10px' }}>Anterior</button>
                    <button className='btn' onClick={handleNext} style={{ marginRight: '10px' }}>Próximo</button>
                  </>
                )}
                <button className='btn' onClick={handleClose} style={{ float: 'right' }}>Fechar</button>
              </div>
            </div>
          )}


          {isShared && (
            <p className='shared-message'>Conteudo já compartilhado</p>
          )}

          {post.tags && post.tags.length > 0 && (
            <div className="tags-container">

              <div className="flexrow">
                {post.tags.map((tag) => (
                  <span key={tag.id} className="tag-nao-selecionada">
                    {tag.nome}
                  </span>
                ))}
              </div>
            </div>
          )}

          <div className='flexrow'>
            <p>Criado em: {formatarData(post.createdAt)}</p>
            {post.validade && (
              <p className="validade">Validade: {formatarData(post.validade)}</p>
            )}
          </div>


          <div className="line300"></div>


          <div className='flexrow'>
            <button className='btn widfit gray' onClick={onVoltar}>Voltar</button>



            {usertype === 'empresa' && <button className='btn gray' onClick={handleDelete}>Excluir</button>}

            {usertype === 'funcionario' && !isShared && isValidToken && (
              <button className='btn' onClick={handleShareLinkedIn}>Compartilhar</button>
            )}

            {usertype === 'funcionario' && !isShared && !isValidToken && (
              <button className='btn' onClick={handleLogin}>Compartilhar</button>
            )}

          </div>

          {usertype === 'funcionario' && !isValidToken && (
            <div>
              <div className='line'></div>
              <span>LinkedIn não conectado. Clique em 'Compartilhar' para conectar.</span>
            </div>
          )}

          {/* Modal de confirmação de exclusão */}
          {showConfirmation && (
            <div className="modal-background">
              <div className="modal">
                <h3>Confirmar Exclusão</h3>
                <p>Deseja realmente excluir o post?</p>
                <div className="modal-buttons">
                  <button onClick={confirmarExclusao}>Sim</button>
                  <button onClick={() => setShowConfirmation(false)}>Cancelar</button>
                </div>
              </div>
            </div>
          )}

          {/* Modal de erro */}
          {showError && (
            <div className="modal-background">
              <div className="modal">
                <h3>Erro</h3>
                <p>{errorMessage}</p>
                <button onClick={() => setShowError(false)}>Fechar</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );


};

export default ConteudoCompleto;
