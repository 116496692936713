import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp"; // Imagem de fallback

const HistoricoTrocas = () => {
  const [trocas, setTrocas] = useState([]);
  const [loading, setLoading] = useState(true);
  const funcionarioId = Cookies.get("funcionarioId");
  const token = Cookies.get("token");

  const fetchHistoricoTrocas = async () => {
    setLoading(true);
    try {
      if (!funcionarioId || !token) {
        throw new Error("Usuário não autenticado");
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/historicoTrocas/${funcionarioId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setTrocas(response.data.trocas);
    } catch (error) {
      console.error("Erro ao buscar histórico de trocas:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistoricoTrocas();
  }, []);

  return (
    <div>
      <div className="tandline">
        <h2>Histórico de Trocas</h2>
        <div className="line300"></div>
      </div>

      {loading ? (
        <p>Carregando...</p>
      ) : (
        <div className="trocas-grid">
          {trocas.length === 0 ? (
            <p>Nenhuma troca realizada.</p>
          ) : (
            trocas.map((troca, index) => (
              <div key={index} className="historico-troca-card">
                <img
                  src={troca.imagem ? `${process.env.REACT_APP_API_URL}/${troca.imagem}` : noprofile}
                  alt={troca.produtoNome || "Produto sem imagem"}
                />
                <div className="historico-troca-info">
                  <h3>{troca.produtoNome}</h3>
                 
                  <p>Data: {new Date(troca.data).toLocaleDateString()}</p>
                  <p className={`historico-troca-status ${troca.status.toLowerCase()}`}>{troca.status}</p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default HistoricoTrocas;
