import React, { useState } from 'react';

const Suporte = ({ togglePopup, isPopupOpen }) => {
  return (
    <div>
      <div className="suporte-container desktop-suporte flexrow ">
        <p className="suporte-text">Precisa de ajuda?</p>
        <div className='flexrow wrap'>
          <a href="https://calendar.app.google/qLCLTPYYD5ZU5XGJA" target="_blank" rel="noopener noreferrer">
            <button className="font14 nowrap btn gray">Agende uma reunião de demonstração</button>
          </a>
          <a href="https://whatsapp.com/channel/0029VaiAhjaKrWQxokaOfq08" target="_blank" rel="noopener noreferrer">
            <button className="font14 nowrap btn gray">Comunidade do WhatsApp</button>
          </a>
          <a href="mailto:suporte@rise.app.br">
            <button className="font14 nowrap btn gray">suporte@rise.app.br</button>
          </a>
        </div>
      </div>

      <div className="mobile-suporte">
        {isPopupOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <p className="suporte-text">Precisa de ajuda?</p>
              <div className='flexrow wrap'>
                <a href="https://calendar.app.google/qLCLTPYYD5ZU5XGJA" target="_blank" rel="noopener noreferrer">
                  <button className="font14 nowrap btn gray">Agende uma reunião de demonstração</button>
                </a>
                <a href="https://whatsapp.com/channel/0029VaiAhjaKrWQxokaOfq08" target="_blank" rel="noopener noreferrer">
                  <button className="font14 nowrap btn gray">Comunidade do WhatsApp</button>
                </a>
                <a href="mailto:suporte@rise.app.br">
                  <button className="font14 nowrap btn gray">suporte@rise.app.br</button>
                </a>
                <button className="font14 nowrap btn gray close-popup-btn" onClick={togglePopup}>Fechar</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Suporte;
