import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import axios from "axios"; // Adicionando axios para a requisição
import Cookies from "js-cookie"; // Para pegar o token e o empresaId
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp"; // Um placeholder caso não tenha imagem do produto
import edit from "../assets/img/edit.svg";

const Produto = ({ produto, onClose, onUpdate, onDelete }) => {
  const [formData, setFormData] = useState({
    id: produto.id, // Adicionando o id do produto ao estado
    nome: produto.nome,
    valor_ups: produto.valor_ups,
    quantidade: produto.quantidade,
  });
  const [fotoProduto, setFotoProduto] = useState(produto.imagem ? produto.imagem : null);
  const [isModified, setIsModified] = useState(false); // Estado para verificar se houve alteração
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onDrop = (acceptedFiles) => {
    setFotoProduto(acceptedFiles[0]);
    setIsModified(true); // Ativa o estado de modificação ao alterar a imagem
  };



  const onExcluir = async (colaborador) => {

    setShowConfirmation(true);
  };


  const confirmarExclusao = async () => {
    const token = Cookies.get('token');
  
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/produto/${produto.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log("Produto excluído com sucesso:", response.data);
      onClose(); // Fechar modal após exclusão
      onDelete(produto.id); // Remover o produto da lista no front-end
    } catch (error) {
      console.error("Erro ao excluir o produto:", error);
      alert("Erro ao excluir o produto. Por favor, tente novamente.");
    }
  };
  


  useEffect(() => {
    setFormData({
      id: produto.id, // Mantém o id no estado
      nome: produto.nome,
      valor_ups: produto.valor_ups,
      quantidade: produto.quantidade,
    });
    setFotoProduto(produto.imagem ? produto.imagem : null);
  }, [produto]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setIsModified(true); // Ativa o estado de modificação ao alterar qualquer campo
  };

  const [cadastroSucesso, setCadastroSucesso] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = Cookies.get('token'); // Pegando o token para autenticação
    const empresaId = Cookies.get('empresaId'); // Pegando o empresaId dos cookies


    // Cria um objeto FormData para enviar a imagem e os dados do produto
    const updatedData = new FormData();
    updatedData.append("id", formData.id);
    updatedData.append("nome", formData.nome);
    updatedData.append("valor_ups", formData.valor_ups);
    updatedData.append("quantidade", formData.quantidade);
    updatedData.append("empresaId", empresaId); // Inclui o empresaId no corpo da requisição

    // Apenas envia a imagem se ela foi alterada
    if (fotoProduto && fotoProduto !== produto.imagem) {
      updatedData.append("imagem", fotoProduto);
    }

    try {
      // Faz a requisição para a rota /api/ajustarproduto
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/ajustarproduto`,
        updatedData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      console.log("Produto atualizado com sucesso:", response.data);

      setCadastroSucesso(true);
      onUpdate(response.data.produto);

    } catch (error) {
      console.error("Erro ao atualizar o produto:", error);

    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (


    



    <div className="flexcolumn">

      

      {showConfirmation && (
        <div className="modal-background">
          <div className="modal">
            <h3>Confirmar Exclusão</h3>
            <p>
              Deseja realmente excluir o produto?
            </p>
            <div className="modal-buttons">
              <button onClick={confirmarExclusao}>Sim</button>
              <button onClick={() => setShowConfirmation(false)}>Cancelar</button>
            </div>
          </div>
        </div>
      )}

      <div className="tandline">
        <h2>Editar Produto</h2>
        <div className="line300"></div>
      </div>
      {cadastroSucesso && (
        <span className="stext legenda">Produto cadastrado com sucesso!</span>
      )}




      <form className="flexcolumn" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="mb15">
          <div {...getRootProps()} className="custom-file-button imagem-editavel">
            <input {...getInputProps()} />
            <div className="imagem-container">
              <img
                className="produto-imagem"
                src={fotoProduto instanceof File
                  ? URL.createObjectURL(fotoProduto)
                  : (produto.imagem ? `https://painel.rise.app.br/${produto.imagem}` : noprofile)}
                alt={fotoProduto ? "Pré-visualização do Produto" : "Imagem do Produto"}
              />
              <div className="overlay3">
                <img src={edit} alt="Editar Foto" className="icone-edit" /> {/* Ícone personalizado */}
              </div>
            </div>
          </div>


          {fotoProduto && (
            <div className="flexrow">
              <div>

              </div>
            </div>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="nome">Nome do Produto:</label>
          <input
            type="text"
            id="nome"
            name="nome"
            value={formData.nome}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="flexrow">
          <div className="form-field">
            <label htmlFor="valor_ups">Valor em UPS:</label>
            <input
              type="number"
              id="valor_ups"
              name="valor_ups"
              value={formData.valor_ups}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-field">
            <label htmlFor="quantidade">Quantidade:</label>
            <input
              type="number"
              id="quantidade"
              name="quantidade"
              value={formData.quantidade}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="form-submit">
          <div className="flexrow" >
            <button type="submit" className="btn-black" disabled={!isModified}>
              <span>Salvar</span>
            </button>
            <button className="btn-black" onClick={onClose}>
              Fechar
            </button>
            <button className="btn-black" onClick={onExcluir}>
              Excluir Produto
            </button>


          </div>
        </div>
      </form>


    </div>
  );
};

Produto.propTypes = {
  produto: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired, // Nova prop para deletar o produto
};




export default Produto;
