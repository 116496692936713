import axios from 'axios';
import Cookies from 'js-cookie';

const verificarPlano = async (empresaId) => {
  console.log('Entrou na função verificarPlano');
  console.log('ID da empresa enviado:', empresaId);

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/atualizar-plano`, {
      params: { empresaId },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      console.log('Plano atualizado com sucesso.');
      
      // Define o cookie plano_nome de acordo com o valor retornado pelo backend
      const { plano_nome } = response.data;
      Cookies.set('plano_nome', plano_nome, { expires: 7 }); // O cookie expira em 7 dias
      console.log('Cookie plano_nome definido como:', plano_nome);

    } else {
      console.error('Erro ao atualizar o plano. Status:', response.status);
    }
  } catch (error) {
    console.error('Erro na requisição de atualização de plano:', error);
  }
};

export default verificarPlano;
