import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const Atualizar = () => {
  // Função para contar os posts do mês e armazenar o resultado em cookies
  const atualizarContagemPosts = async (empresaId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contar-posts`, {
        params: { empresaId },
      });

      if (response.status === 200) {
        const { totalPostsNoMes, limitePosts } = response.data;
        Cookies.set('totalPostsNoMes', totalPostsNoMes); // Armazena o número de posts em um cookie
        Cookies.set('limitePosts', limitePosts); // Armazena o limite de posts em um cookie
        console.log('Número de posts mensais atualizado:', totalPostsNoMes);
        console.log('Limite de posts atualizado:', limitePosts);
      } else {
        console.error('Erro ao atualizar a contagem de posts mensais:', response.status);
      }
    } catch (error) {
      console.error('Erro na requisição para contar posts mensais:', error);
    }
  };

  // Função para buscar e armazenar dados da empresa em cookies
  const fetchAndStoreEmpresaData = async (empresaId) => {
    try {
      console.log(`Fetching empresa data for ID: ${empresaId}`);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/empresa/${empresaId}`, {
        headers: {
          Accept: "application/json",
        },
      });
      const { nome, endereco, adm_nome, telefone, email, logo, emailConfirmed } = response.data;

      Cookies.set('empresaNome', nome);
      console.log('empresaNome set to:', nome);
      Cookies.set('empresaEndereco', endereco);
      console.log('empresaEndereco set to:', endereco);
      Cookies.set('empresaadm_nome', adm_nome);
      console.log('empresaadm_nome set to:', adm_nome);
      Cookies.set('empresaTelefone', telefone);
      console.log('empresaTelefone set to:', telefone);
      Cookies.set('empresaEmail', email);
      console.log('empresaEmail set to:', email);
      Cookies.set('empresaLogo', logo);
      console.log('empresaLogo set to:', logo);
      Cookies.set('empresaemailConfirmed', emailConfirmed);
      console.log('emailConfirmed set to:', emailConfirmed);

      console.log("Empresa data stored in cookies.");
    } catch (error) {
      console.error("Failed to fetch empresa data:", error);
    }
  };

  return {
    atualizarContagemPosts,
    fetchAndStoreEmpresaData,
  };
};

export default Atualizar;
