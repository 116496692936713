import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moreIcon from "../assets/img/more.svg";
import excluir from "../assets/img/excluir.svg";

const GerenciarTags = ({ tagsSelecionadas, setTagsSelecionadas, f_Enterprise }) => {
  const [tags, setTags] = useState([]);
  const [novaTag, setNovaTag] = useState("");
  const [popupAberto, setPopupAberto] = useState(false);
  const [editando, setEditando] = useState(false);
  const [tagsEditadas, setTagsEditadas] = useState({});
  const empresaId = Cookies.get("empresaId");
  const [erroMensagem, setErroMensagem] = useState(""); // Estado para armazenar mensagens de erro
  const [isLoadingAdd, setIsLoadingAdd] = useState(false); // Carregamento para o botão de adicionar
  const [isLoadingSave, setIsLoadingSave] = useState(false); // Carregamento para o botão de salvar edições



  const calculateTextWidth = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = "16px Montserrat"; // Certifique-se de que a fonte é a mesma do input
    return context.measureText(text).width + 20; // Adiciona um buffer de 10px
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tags`, {
          params: { empresaId }
        });
        setTags(response.data);
      } catch (error) {
        console.error("Erro ao buscar tags:", error);
      }
    };

    fetchTags();
  }, [empresaId]);

  const handleAddTag = async () => {
    if (novaTag.trim() === "") {
      setErroMensagem("O nome da tag não pode estar vazio.");
      return;
    }

    const tagExistente = tags.find(
      (tag) => tag.nome.toLowerCase() === novaTag.trim().toLowerCase()
    );
    if (tagExistente) {
      setErroMensagem("Já existe uma tag com esse nome.");
      return;
    }

    setIsLoadingAdd(true); // Ativa o carregamento para o botão de adicionar

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/tags`, {
        nome: novaTag,
        empresaId,
      });

      const novaTagCriada = response.data;
      setTags((prevTags) => [...prevTags, novaTagCriada]);
      setTagsSelecionadas((prevSelecionadas) => [...prevSelecionadas, novaTagCriada]);
      setNovaTag("");
      setErroMensagem("");
    } catch (error) {
      console.error("Erro ao adicionar tag:", error);
      setErroMensagem("Erro ao adicionar a tag. Tente novamente.");
    } finally {
      setIsLoadingAdd(false); // Desativa o carregamento para o botão de adicionar
    }
  };

  const handleSaveEditions = async () => {
    setIsLoadingSave(true); // Ativa o carregamento para o botão de salvar edições

    try {
      await Promise.all(
        Object.entries(tagsEditadas).map(async ([tagId, newNome]) => {
          const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/tags/${tagId}`, {
            nome: newNome,
          });

          // Atualiza o estado local com o nome atualizado
          setTags((prevTags) =>
            prevTags.map((tag) =>
              tag.id === parseInt(tagId) ? { ...tag, nome: response.data.nome } : tag
            )
          );

          // Atualiza as tags selecionadas no frontend
          setTagsSelecionadas((prevSelecionadas) =>
            prevSelecionadas.map((tag) =>
              tag.id === parseInt(tagId) ? { ...tag, nome: response.data.nome } : tag
            )
          );
        })
      );

      setTagsEditadas({});
      setTimeout(() => {
        setEditando(false);
        setIsLoadingSave(false); // Desativa o carregamento após o atraso
      }, 500); // Atraso de 500ms para exibir a animação de salvar
    } catch (error) {
      console.error("Erro ao salvar edições:", error);
      alert("Erro ao salvar edições.");
      setIsLoadingSave(false); // Desativa o carregamento em caso de erro
    }
  };

  const handleTagSelection = (tag) => {
    setTagsSelecionadas((prevSelecionadas) => {
      const isSelected = prevSelecionadas.some((t) => t.id === tag.id);
  
      if (isSelected) {
        return prevSelecionadas.filter((t) => t.id !== tag.id);
      } else {
        return [...prevSelecionadas, tag];
      }
    });
  };
  

  const handleDeleteTag = async (tagId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/tags/${tagId}`);
      setTags(tags.filter((tag) => tag.id !== tagId));
      setTagsSelecionadas(tagsSelecionadas.filter((tag) => tag.id !== tagId));
    } catch (error) {
      console.error("Erro ao remover tag:", error);
    }
  };


  const handleEditTag = (tagId, newNome) => {
    setTagsEditadas((prevTagsEditadas) => ({
      ...prevTagsEditadas,
      [tagId]: newNome,
    }));
  };


  const togglePopup = () => {
    setPopupAberto(!popupAberto);
    if (popupAberto) {
      setEditando(false); // Desativa o modo de edição ao fechar o popup
    }
  };


  const toggleEditMode = () => {
    setEditando(!editando);
  };



  // useEffect que re-renderiza quando as tags são atualizadas
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tags`, {
          params: { empresaId }
        });
        setTags(response.data);
      } catch (error) {
        console.error("Erro ao buscar tags:", error);
      }
    };

    fetchTags();
  }, [empresaId, tagsEditadas]); // Adicione tagsEditadas como dependência para re-renderizar quando editadas




  return (
    <div className="flexcolumn">
      {f_Enterprise ? (
        <>
          <label htmlFor="tags">Tags:</label>
          <div className="flexrow">
            {tags.slice(0, 3).map((tag) => (
              <span
                key={tag.id}
                className={`tag ${tagsSelecionadas.includes(tag) ? "tag-selecionada" : "tag-nao-selecionada"}`}
                onClick={() => handleTagSelection(tag)}
              >
                {tag.nome}
              </span>
            ))}
            <button
              type="button"
              onClick={togglePopup}
              style={{ display: "flex", padding: "0px", background: "none", border: "none", cursor: "pointer" }}
            >
              <img className="custom-file-icon" src={moreIcon} alt="Mais" />
            </button>
          </div>

          {popupAberto && (
            <div>
              <div className="overlay2" onClick={togglePopup}></div>

              <div className="popup">
                <div className="popup-popup-inner flexcolumn center">
                  <h2 className="m10bot">Gerenciar Tags</h2>

                  <div className="flexrow">
                    <input
                      type="text"
                      value={novaTag}
                      className="newtag"
                      onChange={(e) => setNovaTag(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Previne o comportamento padrão (fechar o popup)
                          handleAddTag(); // Chama a função de adicionar tag ao pressionar Enter
                        }
                      }}
                      placeholder="Nova tag"
                      style={{
                        width: `${calculateTextWidth(novaTag || "Nova tag") + 15}px`,
                      }}
                    />


                    <button type="button" className="btn gray" onClick={handleAddTag} disabled={isLoadingAdd}>
                      {isLoadingAdd ? (
                        <div className="loading-container-new">
                          <div className="loading-spinner-new"></div>
                        </div>
                      ) : (
                        "Adicionar"
                      )}
                    </button>


                  </div>


                  {erroMensagem && <p className="erro-mensagem">{erroMensagem}</p>}

                  <div className="line300 m20tb"></div>

                  <div className="flexrow wrap jcenter">
                    {tags.map((tag) => (
                      <div
                        key={tag.id}
                        className={`tag-container ${tagsSelecionadas.includes(tag) ? "tag-selecionada" : "tag-nao-selecionada"} ${editando ? "tag-editando" : ""}`}
                        onClick={() => (editando ? null : handleTagSelection(tag))}
                      >
                        {editando ? (
                          <input
                            type="text"
                            className="inputedit"
                            value={tagsEditadas[tag.id] !== undefined ? tagsEditadas[tag.id] : tag.nome} // Verifica se existe uma edição em andamento
                            onChange={(e) => handleEditTag(tag.id, e.target.value)} // Atualiza o estado tagsEditadas ao digitar
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Previne o comportamento padrão de submissão do formulário
                                handleSaveEditions(); // Chama a função de salvar ao pressionar Enter
                              }
                            }}
                            style={{
                              border: "none",
                              background: "transparent",
                              fontSize: "16px",
                              color: tagsSelecionadas.includes(tag) ? "#fff" : "#343434",
                              width: `${calculateTextWidth(tagsEditadas[tag.id] !== undefined ? tagsEditadas[tag.id] : tag.nome)}px`, // Ajusta o tamanho com base no novo valor
                            }}
                          />

                        ) : (
                          <span
                            style={{
                              color: tagsSelecionadas.includes(tag) ? "#fff" : "#343434",
                            }}
                          >
                            {tag.nome}
                          </span>
                        )}


                        {editando && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 11.3 11.3"
                            className="excluir-tag-icon"
                            style={{
                              fill: tagsSelecionadas.includes(tag) ? "#fff" : "#343434", // Ajusta a cor do ícone
                              cursor: "pointer",
                              width: "10px",
                              height: "10px",
                            }}
                            onClick={() => handleDeleteTag(tag.id)}
                          >
                            <polygon points="11.3 1.4 9.9 0 5.7 4.2 1.4 0 0 1.4 4.2 5.7 0 9.9 1.4 11.3 5.7 7.1 9.9 11.3 11.3 9.9 7.1 5.7 11.3 1.4" />
                          </svg>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="line300 m20tb"></div>

                  {editando ? (
                    <button type="button" className="btn gray p20tb" onClick={handleSaveEditions} disabled={isLoadingSave}>
                      {isLoadingSave ? (
                        <div className="loading-container-new">
                          <div className="loading-spinner-new"></div>
                        </div>
                      ) : (
                        "Salvar Edição"
                      )}
                    </button>
                  ) : (
                    <button type="button" className="btn gray p20tb" onClick={toggleEditMode}>
                      Editar
                    </button>
                  )}

                  <button
                    className="fechar-popup-btn"
                    onClick={togglePopup}
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 11.3 11.3"
                      style={{
                        fill: "#343434", // Ajuste a cor conforme necessário
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <polygon points="11.3 1.4 9.9 0 5.7 4.2 1.4 0 0 1.4 4.2 5.7 0 9.9 1.4 11.3 5.7 7.1 9.9 11.3 11.3 9.9 7.1 5.7 11.3 1.4" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="alert-text">Faça upgrade do plano para usar tags nos posts.</p>
      )}
    </div>
  );
};

export default GerenciarTags;
