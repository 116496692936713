import React from 'react';

const ValiditySetter = ({ f_Enterprise, validadeAtiva, setValidadeAtiva, dataValidade, setDataValidade }) => {
  return (
    <div className="flexrow">
      <div className="checkbox-container">
        <input
          type="checkbox"
          className="custom-switch"
          id="definirValidade"
          checked={validadeAtiva}
          onChange={(e) => setValidadeAtiva(e.target.checked)}
          disabled={!f_Enterprise}
        />
        <label className="label1" htmlFor="definirValidade">
          Definir validade para o conteúdo
        </label>
      </div>

      {validadeAtiva && f_Enterprise && (
        <div className="validity-options">
          <input
            type="date"
            id="dataValidade"
            value={dataValidade || ''}
            onChange={(e) => setDataValidade(e.target.value)}
          />
        </div>
      )}

      {!f_Enterprise && (
        <p className="alert-text">
          Faça upgrade do plano para usar essa função.
        </p>
      )}
    </div>
  );
};

export default ValiditySetter;
