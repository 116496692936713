import React, { useState, useEffect } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_live_51PiGF0RtR7QBHZGfz3BDWoA8FuJybB3RlLIMSPHNCmoFylzNIlOpkfEFts5CF8jfdHACeykFAKCLzMs3MPFNNjND00hOaDcqB1');

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { priceId, empresaId } = location.state || {};
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchClientSecretAndPrice = async () => {
      try {
        if (!priceId || !empresaId) {
          console.error('ID do preço ou da empresa não fornecido.');
          setErrorMessage('ID do preço ou da empresa não fornecido.');
          setLoading(false);
          return;
        }

        console.log('Iniciando a busca pelos detalhes do preço e client secret...');

        const priceResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/get-price-details?priceId=${priceId}`);
        const priceData = await priceResponse.json();

        if (priceResponse.ok) {
          console.log('Detalhes do preço recebidos:', priceData);
          setPrice(priceData);

          console.log('Iniciando a criação da assinatura...');
          const paymentResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/create-subscription`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceId, paymentMethodType: 'card', empresaId }),
          });

          const paymentData = await paymentResponse.json();

          if (paymentResponse.ok) {
            console.log('Client secret recebido:', paymentData.clientSecret);
            setClientSecret(paymentData.clientSecret);
          } else {
            console.error('Erro ao obter clientSecret:', paymentData);
            setErrorMessage('Erro ao obter clientSecret');
          }
        } else {
          console.error('Erro ao obter detalhes do preço:', priceData);
          setErrorMessage('Erro ao obter detalhes do preço');
        }
      } catch (error) {
        console.error('Erro ao buscar clientSecret e detalhes do preço:', error);
        setErrorMessage('Erro ao buscar clientSecret e detalhes do preço');
      } finally {
        setLoading(false);
        console.log('Finalizada a busca de clientSecret e detalhes do preço.');
      }
    };

    fetchClientSecretAndPrice();
  }, [priceId, empresaId]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (errorMessage) {
    return <div>Erro: {errorMessage}</div>;
  }

  if (!clientSecret) {
    console.error('Client secret não encontrado após a tentativa de obtenção.');
    return <div>Erro: clientSecret não encontrado.</div>;
  }

  if (!price) {
    console.error('Detalhes do preço não encontrados após a tentativa de obtenção.');
    return <div>Erro: detalhes do preço não encontrados.</div>;
  }

  console.log('Renderizando formulário de pagamento com clientSecret:', clientSecret);

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentForm clientSecret={clientSecret} price={price} navigate={navigate} />
    </Elements>
  );
};

const PaymentForm = ({ clientSecret, price, navigate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe ou Elements não carregados.');
      return;
    }

    console.log('Iniciando confirmação de pagamento...');

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + '/confirmation',
        },
        redirect: 'if_required'
      });

      if (error) {
        console.error('Erro ao confirmar pagamento:', error);
        setErrorMessage(error.message);
      } else if (paymentIntent.status === 'succeeded') {
        console.log('Pagamento bem-sucedido:', paymentIntent);
        navigate('/confirmation', { state: { paymentIntent } });
      }
    } catch (error) {
      console.error('Erro ao processar pagamento:', error);
      setErrorMessage('Erro ao processar pagamento.');
    }
  };

  const formatCurrency = (value) => {
    return (value / 100).toLocaleString('pt-BR', { style: 'currency', currency: price.currency.toUpperCase() });
  };

  // Dentro do componente PaymentForm
  return (
    <div className="maincenter100 flexcolumn">
      <div>
        <div className="success-message">
          <h1>Assinar</h1>
          <div className="jcenter tandline">
            <div className="line300"></div>
          </div>
          <span>
            Plano {price.nickname || 'Selecionado'} <strong>{formatCurrency(price.amount)}/{price.interval}</strong>
          </span>
        </div>
      </div>

      <form className="formcenter" onSubmit={handleSubmit}>
        <PaymentElement />
        <div className='flexrow'>
        <button className="btn2 " onClick={() => navigate('/')}>
            Voltar
          </button>
          <button className="botao1 " type="submit" disabled={!stripe}>
            Assinar
          </button>
          
        </div>
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </div>
  );
};

export default PaymentPage;
