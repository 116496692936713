import React, { useState, useEffect } from "react";
import axios from "axios";
import ConteudoCompletoPostado from "./ConteudoCompletoPostado"; // Certifique-se de que o caminho para o import está correto
import Cookies from "js-cookie";
import noimage from "../assets/img/noimage.svg";

const ListaPostsFeitos = () => {
  const [posts, setPosts] = useState([]);
  const [postSelecionado, setPostSelecionado] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0); // Estado para controlar o número total de posts
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento inicial

  const email = Cookies.get('empresaEmail');
  console.log(`Email obtido: ${email}`);

  const fetchPosts = async (newOffset = 0) => {
    try {
      const funcionarioId = Cookies.get("funcionarioId"); // Obtenha o funcionarioId do cookie
      const empresaId = Cookies.get("empresaId"); // Obtenha o empresaId do cookie

      let response;
      if (email === 'adm@rise.app.br') {
        console.log("Chamando a rota para todos os posts compartilhados");
        response = await axios.get(`${process.env.REACT_APP_API_URL}/allposts/${empresaId}/shared-posts?limit=6&offset=${newOffset}`);
      } else {
        console.log("Chamando a rota para os posts compartilhados do funcionário");
        response = await axios.get(`${process.env.REACT_APP_API_URL}/funcionarios/${funcionarioId}/shared-posts?limit=6&offset=${newOffset}`);
      }

      console.log("Posts recebidos:", response.data);

      let sharedPosts = response.data.sharedPosts;

      if (newOffset === 0) {
        setPosts(sharedPosts);
      } else {
        setPosts((prevPosts) => [...prevPosts, ...sharedPosts]);
      }

      setTotalPosts(response.data.totalPosts); // Atualize o total de posts
      setLoading(false); // Concluído o carregamento inicial
    } catch (error) {
      console.error("Erro ao buscar posts:", error);
      setLoading(false); // Em caso de erro, definir carregamento como concluído
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    return data.toLocaleDateString();
  };

  const renderMidias = (midias) => {
    if (!midias || midias.trim() === "") return <img src={noimage} alt="Sem mídia disponível" />;

    const midiasLimpa = midias.replace(/[\[\]"\s]/g, "");
    const midiasArray = midiasLimpa.split(",");

    if (midiasArray.length > 0) {
      const primeiraMidia = midiasArray[0].replace(/\\/g, "");
      const urlCompleta = `https://painel.rise.app.br/${primeiraMidia}`;

      // Verificar se a mídia é um vídeo
      const isVideo = primeiraMidia.match(/\.(mp4|webm)$/i);

      if (urlCompleta === "https://painel.rise.app.br/") {
        return <img src={noimage} alt="Sem mídia disponível" />;
      }

      return isVideo ? (
        <video className="b5"
          src={urlCompleta}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          muted
        />
      ) : (
        <img src={urlCompleta} alt="Capa do conteúdo" />
      );
    } else {
      return <img src={noimage} alt="Sem mídia disponível" />;
    }
  };

  const handleVerMais = (post) => {
    setPostSelecionado(post);
  };

  const handleVoltar = () => {
    setPostSelecionado(null);
  };

  const handleLoadMore = () => {
    const newOffset = offset + 6;
    fetchPosts(newOffset);
    setOffset(newOffset);
  };

  if (postSelecionado) {
    return <ConteudoCompletoPostado post={postSelecionado} onVoltar={handleVoltar} />;
  }

  return (
    <div>
      {loading ? (
        <div>Carregando...</div>
      ) : (
        <>
          <div className="tandline">
            <h2>Conteúdos Já Compartilhados</h2>
            <div className="line300"></div>
          </div>
          <div className="gap20 flexcolumn m20b">
            {posts.length > 0 ? (
              posts.map((post, index) => (
                <div key={index} className="flexrow">
                  <div className="imagepost">{renderMidias(post.midias)}</div>
                  <div className="flexcolumn">
                    <p className="legenda">{post.legenda}</p>
                   
                   
                    {email === 'adm@rise.app.br' && (
                      <div className="flexcolumn">
                        <p className="funcionario-info">
                          Empresa: {post.empresaNome}<br></br>

                        </p>
                        <p className="funcionario-info">

                          Colaborador: {post.funcionarioNome}
                        </p>
                      </div>

                    )}
                    <div className="flexrow">
                      <button className="btn gray" onClick={() => handleVerMais(post)}>Ver mais</button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Nenhum post encontrado.</p>
            )}
          </div>
          {posts.length < totalPosts && (
            <div className="flexrow">
              <button className="btn gray" onClick={handleLoadMore}>Carregar Mais</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ListaPostsFeitos;
