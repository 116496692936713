import React from 'react';
import { useNavigate } from 'react-router-dom';

const EmailConfirmado = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <div className='centerflex wh100 grafite-bg' >
    <div className='cxlogin flexcenter gap10' >
      <h3>E-mail confirmado com sucesso!</h3>
      <button onClick={handleRedirect} className= 'botao1'  >OK</button>
    </div>
    </div>
  );
};

export default EmailConfirmado;
