import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Para capturar o postId da URL
import axios from 'axios';
import Cookies from 'js-cookie';

const ConteudoPorLink = ({ onVoltar, setFuncionarioData }) => {
  const { postId } = useParams(); // Obtenha o postId da URL
  const [post, setPost] = useState(null); // Estado para armazenar o post
  const [loading, setLoading] = useState(true); // Controle de carregamento

  // Função para buscar o post pelo postId
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const token = Cookies.get('token'); // Pegue o token dos cookies
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/post/${postId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPost(response.data); // Atualize o estado com o post recebido
        setLoading(false); // Desligue o indicador de carregamento
      } catch (error) {
        console.error('Erro ao carregar o post:', error);
        setLoading(false);
      }
    };

    fetchPost(); // Chame a função de busca ao carregar o componente
  }, [postId]);

  if (loading) {
    return <div>Carregando...</div>; // Exibe carregando até o post ser carregado
  }

  if (!post) {
    return <div>Post não encontrado</div>; // Exibe caso o post não seja encontrado
  }

  // Renderiza o conteúdo do post
  return (
    <div className='flexcolumn'>
      <h2>Conteúdo Completo do Post</h2>
      <p className="ups">+ {post.ups} UPS</p>
      <p>{post.legenda}</p>
      {/* Resto do código para renderizar o post */}
      <button className='btn widfit gray' onClick={onVoltar}>Voltar</button>
    </div>
  );
};

export default ConteudoPorLink;
