import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const removeAllCookies = () => {
    const cookies = Cookies.get();
    for (let cookie in cookies) {
        Cookies.remove(cookie);
    }
    console.log("Todos os cookies foram removidos.");
};

const useLogout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        removeAllCookies();
        console.log("User has logged out and all cookies are cleared.");
        navigate('/');  // Ajuste para a rota correta de login ou início
    };

    return handleLogout;
};

export default useLogout;
