import React, { useState, useEffect } from "react";
import axios from "axios";
import ConteudoCompleto from "./ConteudoCompleto";
import Cookies from "js-cookie";
import noimage from "../assets/img/noimage.svg";

const ListaPosts = ({ setFuncionarioData }) => { // Receba setFuncionarioData como prop
  const [posts, setPosts] = useState([]);
  const [postSelecionado, setPostSelecionado] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0); // Estado para controlar o número total de posts
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento inicial
  const email = Cookies.get('email');//email obtido para super admin
  console.log('Email set to:', email);

  const empresaNome = Cookies.get('empresaNome');
  
  



  const fetchPosts = async (newOffset = 0) => {
    try {
      const empresaId = Cookies.get("empresaId"); // Obtenha o empresaId do cookie
      const user = Cookies.get('user'); // Recuperar o valor do cookie 'user'

      const funcionarioId = Cookies.get("funcionarioId"); // Obtenha o funcionarioId do cookie
      const token = Cookies.get('token'); // Obtenha o token do cookie

      if (!empresaId) {
        console.error("ID da empresa não encontrado no cookie.");
        return; // Sai cedo se o ID da empresa não estiver disponível
      }

      if (!token) {
        console.error("Token de autenticação não encontrado no cookie.");
        return; // Sai cedo se o token não estiver disponível
      }

      console.log("Empresa ID enviado:", empresaId); // Adicione este log para mostrar o ID de empresa enviado

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {
        params: { empresaId, funcionarioId, limit: 6, offset: newOffset },
        headers: { Authorization: `Bearer ${token}` },
      });

      let fetchedPosts = response.data.rows;

      // Filtrar posts se o usuário for 'funcionario'
      if (user === 'funcionario') {
        const now = new Date();
        fetchedPosts = fetchedPosts.filter(post => {
          const validade = post.validade ? new Date(post.validade) : null;
          return !validade || validade >= now; // Filtra posts sem validade ou com validade no futuro
        });
      }


      console.log("Posts received:", response.data); // Log para verificar os posts recebidos, incluindo legendas alteradas

      // Adicione logs para verificar se as legendas editadas estão chegando
      response.data.rows.forEach(post => {
        console.log("Post:", post);
        if (post.legendasEditadas) {
          console.log("Legendas Editadas:", post.legendasEditadas);
        }
      });

      if (newOffset === 0) {
        setPosts(fetchedPosts); // Use os posts filtrados
      } else {
        setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
      }

      setTotalPosts(response.data.count); // Atualize o total de posts
      setLoading(false); // Concluído o carregamento inicial
    } catch (error) {
      console.error("Erro ao buscar posts:", error);
      setLoading(false); // Em caso de erro, definir carregamento como concluído
    }
  };





  useEffect(() => {
    fetchPosts();
  }, []);

  const handleLoadMore = () => {
    const newOffset = offset + 6;
    fetchPosts(newOffset);
    setOffset(newOffset);
  };

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    return data.toLocaleDateString();
  };

  const renderMidias = (midias) => {
    if (!midias || midias.trim() === "") return <img src={noimage} alt="Sem mídia disponível" />;

    const midiasLimpa = midias.replace(/[\[\]"\s]/g, "");
    const midiasArray = midiasLimpa.split(",");

    if (midiasArray.length > 0) {
      const primeiraMidia = midiasArray[0].replace(/\\/g, "");
      const urlCompleta = `https://painel.rise.app.br/${primeiraMidia}`;

      // Verificar se a mídia é um vídeo
      const isVideo = primeiraMidia.match(/\.(mp4|webm)$/i);

      if (urlCompleta === "https://painel.rise.app.br/") {
        return <img src={noimage} alt="Sem mídia disponível" />;
      }

      return isVideo ? (
        <video className="b5"
          src={urlCompleta}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          muted
        />
      ) : (
        <img src={urlCompleta} alt="Capa do conteúdo" />
      );
    } else {
      return <img src={noimage} alt="Sem mídia disponível" />;
    }
  };

  const handleVerMais = (post) => {
    setPostSelecionado(post);
  };

  const handleVoltar = () => {
    setPostSelecionado(null);
  };

  if (postSelecionado) {
    return <ConteudoCompleto
      post={postSelecionado}
      onVoltar={handleVoltar}
      onLoadPosts={fetchPosts}
      setFuncionarioData={setFuncionarioData} // Passe setFuncionarioData para ConteudoCompleto
    />;
  }

  return (
    <div>
      {loading ? (
        <div>Carregando...</div>
      ) : (
        <>
          <div className="tandline">
            <h2>Contéudos Disponíveis</h2>
            <div className="line300"></div>
          </div>
          <div className="gap20 flexcolumn">
            {posts.map((post) => (
              <div key={post.id} className="flexrow">
                <div className="imagepost">{renderMidias(post.midias)}</div>
                <div className="flexcolumn">
                  <p className="ups">UPS {post.ups}</p>
                  <p className="m0">{post.legenda}</p>

                  <p className={post.createdAt ? "dnone" : ""}>{formatarData(post.createdAt)}</p>
                  {post.validade && (
                    <p className="m0">Validade: {formatarData(post.validade)}</p>
                  )}

                  {email === 'adm@rise.app.br' && (
                    <p className="m0">Empresa: {empresaNome}</p>
                  )}

                  <div className="flexrow">
                    <button className="btn gray" onClick={() => handleVerMais(post)}>Ver mais</button>
                  </div>
                </div>
              </div>

            ))}
          </div>
          {posts.length < totalPosts && (
            <div className="flexrow">
              <button className="btn gray m20top" onClick={handleLoadMore}>Carregar Mais</button>
            </div>
          )}
        </>
      )}
    </div>
  );

};

export default ListaPosts;
