import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import logoblack from "./logoblack.svg";

const RecuperacaoSenha = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [token, setToken] = useState('');
  const [etapa, setEtapa] = useState('solicitar'); // 'solicitar' ou 'redefinir'
  const [mensagem, setMensagem] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar o loader
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tokenFromURL = query.get('token');
    if (tokenFromURL) {
      setToken(tokenFromURL);
      setEtapa('redefinir');
    }
  }, [location.search]);

  const handleSolicitarRecuperacao = async () => {
    setIsLoading(true); // Ativa o loader
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/solicitar-recuperacao`, { email });
      setMensagem('Se o e-mail pertence a uma conta, um e-mail será enviado com as instruções para redefinir sua senha.');
    } catch (error) {
      setMensagem('Se o e-mail pertence a uma conta, um e-mail será enviado com as instruções para redefinir sua senha.');
    }
    setIsLoading(false); // Desativa o loader
  };

  const handleRedefinirSenha = async () => {
    setIsLoading(true); // Ativa o loader
    if (senha !== confirmarSenha) {
      setMensagem('As senhas não coincidem.');
      setIsLoading(false); // Desativa o loader caso haja erro
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset-password/${token}`, { senha });
      setMensagem(response.data.message);
    } catch (error) {
      setMensagem(error.response ? error.response.data.message : 'Erro ao redefinir a senha.');
    }
    setIsLoading(false); // Desativa o loader
  };

  return (
    <div className="centerflex gap20 centerflex wh100 grafite-bg">
      <div className="cxlogin gap20">
        <div className='h30' >
          <img className="w50 pd15" src={logoblack} alt="Logo Black" />
        </div>
  
        {isLoading ? (
          <div className="loading-container2">
            <div className="loading-spinner2"></div>
          </div>
        ) : (
          <div className='centerflex h70'>
            <h2>{etapa === 'solicitar' ? 'Recuperação de Senha' : 'Redefinir Senha'}</h2>
            {etapa === 'solicitar' ? (
              <>
                <input className='text_center'
                  type="email"
                  placeholder="Digite seu e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="botao1" onClick={handleSolicitarRecuperacao}>Enviar</button>
              </>
            ) : (
              <>
                <input className='text_center'
                  type="password"
                  placeholder="Nova senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
                <input className='text_center'
                  type="password"
                  placeholder="Confirme a nova senha"
                  value={confirmarSenha}
                  onChange={(e) => setConfirmarSenha(e.target.value)}
                />
                <button className="botao1" onClick={handleRedefinirSenha}>Redefinir</button>
              </>
            )}
            <a href='/' className="cinza f12">
              Voltar
            </a>
            <p className='precsenha'>{mensagem}</p>
          </div>
        )}
      </div>
    </div>
  );
  };
  
  export default RecuperacaoSenha;
  
