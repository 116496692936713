import React, { useState, useRef } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import AvatarEditor from "react-avatar-editor";
import "./css/ea.css";
import more from "./assets/img/more.svg";
import removeicon from "./assets/img/remove.svg";

const CadastrarEmpresa = () => {
  const [formData, setFormData] = useState({
    nome: "",
    endereco: "",
    adm_nome: "",
    telefone: "",
    email: "",
    senha: "",
    confirmarSenha: "",
  });
  const [logo, setLogo] = useState(null);
  const [zoom, setZoom] = useState(1);
  const editorRef = useRef(null);
  const [erroMsg, setErroMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento
  const navigate = useNavigate(); // Usar o hook useNavigate

  const onDrop = (acceptedFiles) => {
    setLogo(acceptedFiles[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErroMsg("");
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fieldLabels = {
    nome: "Razão Social",
    endereco: "Endereço",
    adm_nome: "Nome do Administrador",
    telefone: "Telefone",
    email: "Email",
    senha: "Senha",
    confirmarSenha: "Confirmar Senha",
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleZoomChange = (e) => {
    setZoom(parseFloat(e.target.value));
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  const { senha, confirmarSenha, ...rest } = formData;
  if (senha !== confirmarSenha) {
    setErroMsg("As senhas não correspondem.");
    setIsLoading(false);
    return;
  }

  try {
    const formDataToSend = new FormData();

    if (logo) {
      const editor = editorRef.current;
      if (editor) {
        const canvasScaled = editor.getImageScaledToCanvas();
        const blob = await new Promise((resolve, reject) => {
          canvasScaled.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Falha na conversão da imagem para Blob."));
              }
            },
            "image/webp",
            0.9
          );
        });

        formDataToSend.append("logo", blob, `logo.webp`);
      }
    }

    for (const key in rest) {
      formDataToSend.append(key, rest[key]);
    }

    formDataToSend.append("senha", senha);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cadastrar-empresa`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const { empresaId } = response.data;

    
    navigate("/EscolherPlano", { state: { cadastroSucesso: true, empresaId } });
  } catch (error) {
    console.error("Erro ao cadastrar empresa:", error);
    if (error.response && error.response.status === 400) {
      setErroMsg("O e-mail fornecido já está em uso.");
    } else {
      setErroMsg("Erro ao cadastrar empresa. Por favor, tente novamente mais tarde.");
    }
  } finally {
    setIsLoading(false);
  }
};


  return (
    <div className="maincenter100">
      <div className="flexcolumn max60">
        <div className="tandline">
          <h2>Cadastrar Empresa</h2>
          <div className="line300"></div>
        </div>

        {isLoading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
          </div>
        ) : (
          <form   onSubmit={handleSubmit} encType="multipart/form-data">
            {!logo && (
              <div {...getRootProps()} className="border100 custom-file-button">
                <input {...getInputProps()} />
                <img
                  className="custom-file-icon"
                  src={more}
                  alt="Selecionar Imagem"
                />
              </div>
            )}

            {logo && (
              <div className="flexcolumn">
                <div className="flexrow ">
                  <div className="perfil-avatar">
                    <AvatarEditor
                      ref={editorRef}
                      image={URL.createObjectURL(logo)}
                      width={200}
                      height={200}
                      border={0}
                      borderRadius={125}
                      color={[255, 255, 255, 255]}
                      scale={zoom}
                      rotate={0}
                      backgroundColor={"transparent"}
                    />
                  </div>
                  <div>
                    <div className="btn-red" onClick={() => setLogo(null)}>
                      <img
                        className="wid12"
                        src={removeicon}
                        alt="Remover Logo"
                      />
                    </div>
                    <input
                      type="range"
                      min="1"
                      max="2"
                      step="0.01"
                      value={zoom}
                      onChange={handleZoomChange}
                      className="zoom-slider"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="form-wrapper gap10 mt15">
              {Object.entries(formData).map(([key, value], index) => (
                <div
                  key={key}
                  className={`field left_t ${
                    index === 0 ? "full-width" : "half-width"
                  }`}
                >
                  <label htmlFor={key}>
                    {fieldLabels[key] ||
                      key.charAt(0).toUpperCase() + key.slice(1)}
                  </label>
                  <input
                    type={
                      key.includes("senha") || key.includes("confirmarSenha")
                        ? "password"
                        : "text"
                    }
                    id={key}
                    name={key}
                    value={value}
                    onChange={handleChange}
                    required
                  />
                </div>
              ))}
            </div>

            {erroMsg && <div className="error-msg">{erroMsg}</div>}

            <div className="form-submit">
              <button type="submit" className=" m10top btn gray btn-submit">
                Salvar
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CadastrarEmpresa;
