import React, { useState } from 'react';
import "./css/ea.css";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import logoblack from "./logoblack.svg";

const DefinirSenha = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [erroMsg, setErroMsg] = useState('');
  const [senhaDefinida, setSenhaDefinida] = useState(false);

  console.log('Componente DefinirSenha carregado'); // Adicione esta linha para verificar

  const handleSubmit = async (e) => {
    e.preventDefault();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    if (!token) {
      setErroMsg('Token inválido ou expirado.');
      return;
    }

    if (senha !== confirmarSenha) {
      setErroMsg('As senhas não correspondem.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/definir-senha`, 
      { senha }, 
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token: token
        }
      });

      if (response.status === 200) {
        setSenhaDefinida(true);
      } else {
        setErroMsg('Erro ao redefinir a senha. Tente novamente mais tarde.');
      }
    } catch (error) {
      console.error('Erro ao redefinir a senha:', error);
      setErroMsg('Erro ao redefinir a senha. Tente novamente mais tarde.');
    }
  };

  if (senhaDefinida) {
    return (
      <div className='centerflex wh100 grafite-bg'>
        <div className='cxlogin flexcenter gap10'>
          <h3>Senha Definida com Sucesso!</h3>
          <button onClick={() => navigate('/')} className='botao1'>OK</button>
        </div>
      </div>
    );
  }

  return (
    <div className="centerflex wh100 grafite-bg">
      <div className="cxlogin flexcenter gap10">

      <div className="h30" >
          <img className="w50 pd15" src={logoblack} alt="Logo Black" />
        </div>
     
        {erroMsg && <div className="error-msg">{erroMsg}</div>}
        <form onSubmit={handleSubmit} className="flexcolumn gap10 w60 center">
        <h3 className='mb15' >Definir Senha</h3>
          <input
            type="password"
            placeholder="Nova Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
            
          />
          <input
            type="password"
            placeholder="Confirmar Senha"
            value={confirmarSenha}
            onChange={(e) => setConfirmarSenha(e.target.value)}
            required
            
          />
          <button type="submit" className="botao1">Definir</button>
        </form>
      </div>
    </div>
  );
  


};

export default DefinirSenha;
