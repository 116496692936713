import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp";
import LoadingSpinner from "./LoadingSpinner";
import Produto from "./Produto";

const ListaProdutos = () => {
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = Cookies.get('user');
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [produtoTrocar, setProdutoTrocar] = useState(null); // Estado para o produto a ser trocado
  const [showConfirmPopup, setShowConfirmPopup] = useState(false); // Controle do popup de confirmação
  const [loadingTroca, setLoadingTroca] = useState(false); // Estado de loading durante a troca

  const handleGerenciar = (produto) => {
    setProdutoSelecionado(produto);
  };

  const handleDelete = (produtoId) => {
    setProdutos((prevProdutos) =>
      prevProdutos.filter((produto) => produto.id !== produtoId)
    );
  };
  

  const handleClose = () => {
    setProdutoSelecionado(null);
  };

  const updateProdutoInList = (updatedProduto) => {
    setProdutos((prevProdutos) =>
      prevProdutos.map((produto) =>
        produto.id === updatedProduto.id ? updatedProduto : produto
      )
    );
  };

  // Função para abrir o popup de confirmação
  const handleTrocarClick = (produto) => {
    console.log("Tentando trocar o produto:", produto.nome); // Log para verificar se o produto está sendo capturado

    setProdutoTrocar(produto); // Definir o produto para troca
    setShowConfirmPopup(true); // Exibir o popup de confirmação
  };


  const handleConfirmarTroca = async () => {
    console.log("Chamando handleConfirmarTroca..."); // Log para verificar se está chamando a função
    setLoadingTroca(true);
    
    const token = Cookies.get("token");
    const funcionarioId = Cookies.get("funcionarioId");

    // Adicionando logs para verificar os valores
    console.log('Token obtido:', token);
    console.log('ID do funcionário obtido dos cookies:', funcionarioId);
    console.log('Produto a ser trocado:', produtoTrocar ? produtoTrocar.nome : 'Nenhum produto selecionado');
    console.log('Produto ID:', produtoTrocar ? produtoTrocar.id : 'Nenhum produto ID');

    if (!funcionarioId) {
      console.error("ID do funcionário não encontrado nos cookies.");
      setLoadingTroca(false);
      return;
    }

    try {
      console.log("Iniciando troca para o produto:", produtoTrocar.nome, "com UPS:", produtoTrocar.valor_ups);

      // Log de todos os parâmetros que serão enviados
      console.log("Parâmetros que serão enviados para a API:", {
        produtoId: produtoTrocar.id,
        funcionarioId: funcionarioId,
      });

      // Modificar para GET e passar os parâmetros na URL
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trocarups`, 
        {
          params: {
            produtoId: produtoTrocar.id,
            funcionarioId: funcionarioId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Troca realizada com sucesso:", response.data);
      setShowConfirmPopup(false); // Fechar popup após a troca
      fetchProdutos(); // Atualizar a lista de produtos após a troca
    } catch (error) {
      console.error("Erro ao trocar UPS:", error.response ? error.response.data : error.message);
      alert("Erro ao realizar a troca. Por favor, tente novamente.");
    } finally {
      setLoadingTroca(false);
    }
};





  // Função para buscar os produtos da empresa
  async function fetchProdutos() {
    setLoading(true);
    try {
      const empresaId = Cookies.get("empresaId");
      const token = Cookies.get("token");

      if (!token) {
        throw new Error("Usuário não autenticado");
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/produtos/${empresaId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProdutos(response.data.produtos);
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProdutos();
  }, []);

  return (
    <div>
      {!produtoSelecionado && (
        <div className="tandline">
          <h2>Produtos</h2>
          <div className="line300"></div>
        </div>
      )}

      {loading ? (
        <LoadingSpinner />
      ) : produtoSelecionado ? (
        <Produto
          produto={produtoSelecionado}
          onClose={handleClose}
          onUpdate={updateProdutoInList}
          onDelete={handleDelete}  // Nova prop para deletar o produto
        />
      ) : (
        <div className="produtos-grid">
          {produtos.length === 0 ? (
            <p>Nenhum produto encontrado.</p>
          ) : (
            produtos.map((produto, index) => (
              <div key={index} className="produto-card">
               <img
  className="produto-imagem"
  src={produto.imagem ? `https://painel.rise.app.br/${produto.imagem}` : noprofile}
  alt={produto.nome}
/>

                <div className="produto-info gap5 flexcolumn">
                  <h3>{produto.nome}</h3>

                  <span className="produto-ups">{produto.valor_ups} UPS</span>
                  {user === 'funcionario' ? (
                    <button className="botao1" onClick={() => handleTrocarClick(produto)}>
                      Trocar
                    </button>
                  ) : (
                    <button className="botao1" onClick={() => handleGerenciar(produto)}>
                      Gerenciar
                    </button>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      )}

      {showConfirmPopup && (
        <div className="modal-background">
          <div className="modal">
            <h3>Confirmar Troca</h3>
            <p>
              Tem certeza de que deseja trocar {produtoTrocar?.nome} por {produtoTrocar?.valor_ups} UPS?
            </p>
            <div className="modal-buttons">
              <button onClick={handleConfirmarTroca} disabled={loadingTroca}>
                {loadingTroca ? "Trocando..." : "Sim"}
              </button>
              <button onClick={() => setShowConfirmPopup(false)}>Cancelar</button>
            </div>
          </div>
        </div>
      )}



    </div>
  );
};

export default ListaProdutos;
