import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { paymentIntent } = location.state || {};

  if (!paymentIntent) {
    return <div>Erro: Detalhes do pagamento não encontrados.</div>;
  }

  const handleReturnHome = () => {
    navigate('/');
  };

  return (



    <div className="maincenter100 flexcolumn">
    <div>
      <div className="success-message">
      <h1>Pagamento Confirmado!</h1>
        <div className="jcenter tandline">
          <div className="line300"></div>
        </div>


        <span className='legenda'>
        ID do Pagamento: {paymentIntent.id}
        </span>

        

        
        <button className="botao1 m10top margin_c" onClick={handleReturnHome}>Retornar</button>



      </div>
    </div>


    

    
  </div>
    





    
  );
};

export default ConfirmationPage;
