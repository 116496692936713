import React from "react";
import "../css/ea.css"; // Certifique-se de que o CSS para o spinner esteja incluído

const LoadingSpinner = () => {
  return (
    <div className="loading-container2">
      <div className="loading-spinner2"></div>
    </div>
  );
};

export default LoadingSpinner;
